import React, { useEffect, useRef } from 'react'

import { PlaceholderBallonPluginProps, PlaceholderBallonPlugin } from '../placeholder'

export type PlaceholderBallonProps = Omit<PlaceholderBallonPluginProps, 'element'> & {
  className?: string
  tippyElementId: string
}

export const PlaceholderBallonHandler: React.FC<React.PropsWithChildren<PlaceholderBallonProps>> = (props) => {
  const element = useRef<HTMLDivElement>(null)

  useEffect(
    () => {
      const { pluginKey, editor, tippyOptions, shouldShow } = props

      editor.registerPlugin(
        PlaceholderBallonPlugin({
          pluginKey,
          editor,
          element: element.current as HTMLElement,
          tippyOptions,
          shouldShow,
        }),
      )

      return () => {
        editor.unregisterPlugin(pluginKey)
      }
    },
    // Avoid keys errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div id={props.tippyElementId} ref={element} className={props.className} style={{ visibility: 'hidden' }}>
      {props.children}
    </div>
  )
}
