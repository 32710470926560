import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import styles from './Heading.module.scss'
import cx from 'classnames'
import { EAppearAnimation, ETypography } from '@/interfaces'
import { useInView } from 'react-intersection-observer'
import { INTERSECT_THRESHOLD } from '@/constants'

export enum EHeadingType {
  h1 = 'h1',
  h2 = 'h2',
  intro = 'intro',
  footer = 'footer',
}

type Props = {
  type?: EHeadingType
  text: string
  subtext: string
  description?: string
  left?: boolean
  styleInline?: CSSProperties
  appear?: boolean
}

const { mh1, mh2, sh1, sh2, sh2footer, mh2footer } = ETypography

const Heading = ({ type = EHeadingType.h1, text, subtext, description, left, styleInline, appear }: Props) => {
  const [textStyles, subtextStyles] = useMemo(() => {
    let textStyles = cx(styles.text, sh1)
    let subtextStyles = cx(styles.text, mh1)
    if (type === EHeadingType.h2) {
      textStyles = cx(styles.texth2, sh2)
      subtextStyles = cx(styles.texth2, mh2, styles.gradient)
    } else if (type === EHeadingType.footer) {
      textStyles = cx(styles.textFooter, sh2footer)
      subtextStyles = cx(styles.textFooter, mh2footer, styles.gradient)
    }
    return [textStyles, subtextStyles]
  }, [type])

  const [appearOnce, setAppearOnce] = useState<boolean>(false)

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: INTERSECT_THRESHOLD,
  })

  // check if is inView
  useEffect(() => {
    if (inView) {
      setAppearOnce(true)
    }
  }, [inView])

  let texts

  if (type === EHeadingType.intro) {
    texts = (
      <>
        <h1
          className={cx(styles.text, EAppearAnimation.fromBottom, EAppearAnimation.delay1, {
            [EAppearAnimation.show]: appear,
          })}
        >
          <span className={sh1}>{text}</span>
          <span className={mh1}>{subtext}</span>
        </h1>
      </>
    )
  } else {
    texts = (
      <>
        <h1
          className={cx(textStyles, EAppearAnimation.fromBottom, {
            [EAppearAnimation.show]: appearOnce,
          })}
        >
          {text}
        </h1>
        <h2
          className={cx(subtextStyles, EAppearAnimation.fromBottom, EAppearAnimation.delay1, {
            [EAppearAnimation.show]: appearOnce,
          })}
        >
          {subtext}
        </h2>
      </>
    )
  }

  return (
    <div
      ref={ref}
      className={cx(styles.heading, {
        [styles.isLeft]: left,
      })}
      style={styleInline}
    >
      {texts}
      {description && (
        <p
          className={cx(
            ETypography.subtitle,
            styles.description,
            EAppearAnimation.fromBottom,
            EAppearAnimation.delay1,
            {
              [EAppearAnimation.show]: appear,
            },
          )}
        >
          {description}
        </p>
      )}
    </div>
  )
}

export default Heading
