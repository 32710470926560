import React, { useEffect, useRef, useState } from 'react'
import styles from './Members.module.scss'
import bkgImg from '@/assets/images/members/bkg.png'
import cx from 'classnames'
import { EAppearAnimation, ETypography } from '@/interfaces'
import { useInView } from 'react-intersection-observer'
import { INTERSECT_THRESHOLD } from '@/constants'
import DATA from '@/data/members'

const Members = () => {
  const [index, setIndex] = useState<number>(0)
  const [second, setSecond] = useState<number>(1)
  const [isMoving, setIsMoving] = useState<number>(null)
  const [appearOnce, setAppearOnce] = useState<boolean>(false)
  const [msgIndex, setMsgIndex] = useState<number>(0)
  const cardArr = useRef<number[]>([0, 1, 2])
  const incr = useRef<number>(0)

  useEffect(() => {
    let interval
    if (appearOnce) {
      interval = setInterval(() => {
        const nextIndex = index + 1 > cardArr.current.length - 1 ? 0 : index + 1
        const nextSecond = second + 1 > cardArr.current.length - 1 ? 0 : second + 1

        setIsMoving(index)

        setTimeout(() => {
          setIsMoving(null)
          let card1 = cardArr.current[0]
          let card2 = cardArr.current[1]
          let card3 = cardArr.current[2]

          if (incr.current === 0) {
            // increase first card
            card1 += 3
            if (card1 > DATA.length - 1) {
              card1 -= DATA.length
            }
            incr.current++
          } else if (incr.current === 1) {
            // increase second card
            card2 += 3
            if (card2 > DATA.length - 1) {
              card2 -= DATA.length
            }
            incr.current++
          } else if (incr.current === 2) {
            // increase third card
            card3 += 3
            if (card3 > DATA.length - 1) {
              card3 -= DATA.length
            }
            incr.current = 0
          }

          const nextMsgIndex = msgIndex + 1 > DATA.length - 1 ? 0 : msgIndex + 1
          cardArr.current = [card1, card2, card3]
          setMsgIndex(nextMsgIndex)
        }, 1000)

        setIndex(nextIndex)
        setSecond(nextSecond)
      }, 2500)
    }
    return () => {
      clearInterval(interval)
      // clearTimeout(timeout)
    }
  }, [index, second, appearOnce, msgIndex])

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: INTERSECT_THRESHOLD,
  })

  // check if is inView
  useEffect(() => {
    if (inView) {
      setAppearOnce(true)
    }
  }, [inView])

  return (
    <div className={styles.container}>
      <img className={styles.bkg} src={bkgImg.src} alt="" />
      <div
        ref={ref}
        className={cx(styles.inner, EAppearAnimation.bounce, {
          [EAppearAnimation.show]: appearOnce,
        })}
      >
        {cardArr.current.map((cardId, cardCount) => (
          <div
            key={`member-${cardCount}`}
            className={cx(styles.card, {
              [styles.first]: cardCount === index,
              [styles.second]: cardCount === second,
              [styles.isMoving]: cardCount === isMoving,
            })}
          >
            <div className={styles.left}>
              <img className={styles.profil} src={DATA[cardId].url} alt="Lazy Members" />
            </div>
            <div className={styles.content}>
              <div className={styles.titles}>
                <span className={styles.username}>{DATA[cardId].username}</span>
                <span className={cx(styles.tag, ETypography.small)}>{DATA[cardId].tag}</span>
              </div>
              <p className={styles.text} dangerouslySetInnerHTML={{ __html: DATA[cardId].text }}></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Members
