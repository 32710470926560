import { NodeViewWrapper } from '@tiptap/react'
import CardLinkView, { CardLinkViewProps } from './CardLinkView'

export type ReferenceItemViewProps = Pick<CardLinkViewProps, 'customTitle' | 'card' | 'onOpen'>

const ReferenceItemView = (props: ReferenceItemViewProps) => {
  return (
    <NodeViewWrapper className="reference" as={'span'} style={{ display: 'inline' }} id={Math.random().toString()}>
      <CardLinkView {...props} variant="referenceLink" arrowIcon />
    </NodeViewWrapper>
  )
}

export default ReferenceItemView
