import React, { memo } from 'react'
import styles from './ModalTry.module.scss'
import cx from 'classnames'
import { ETypography } from '@/interfaces'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import LSvg from '@/assets/icons/L.svg'
// import CmdSvg from '@/assets/icons/cmd.svg'

type Props = {
  onClick: (props: any) => void
}

const ModalTry = ({ onClick }: Props) => {
  const { modalTry } = useSelector((state: RootState) => state.launcher)

  return (
    <div className={cx(styles.modal, { [styles.show]: modalTry })} onClick={onClick}>
      <div className={cx(styles.container, ETypography.small)}>
        <p>Click or press </p>
        <LSvg className={styles.icon}></LSvg>
        {/* <CmdSvg className={styles.icon}></CmdSvg> */}
        <p> to try live</p>
      </div>
    </div>
  )
}

export default memo(ModalTry)
