import React from 'react'
import styles from './Banner.module.scss'
import cx from 'classnames'
import { EAppearAnimation, ETypography } from '@/interfaces'
import Image from 'next/image'
import separatorImg from '@/assets/icons/separator.png'

type Props = {
  text: string
  subtext: string
  appear?: boolean
}

const Banner = ({ text, subtext, appear }: Props) => (
  <div
    className={cx(styles.banner, EAppearAnimation.fromBottom, {
      [EAppearAnimation.show]: appear,
    })}
  >
    <div className={styles.textWrapper}>
      <div className={styles.lineLeft}></div>
      <a
        className={cx(ETypography.small, styles.text)}
        href="https://www.producthunt.com/posts/lazy-so"
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </a>
      <div className={styles.separatorWrapper}>
        <Image src={separatorImg} layout="responsive"></Image>
      </div>
      <a className={cx(ETypography.small, styles.subtext)}
        href="https://www.producthunt.com/posts/lazy-so"
        target="_blank"
        rel="noreferrer"
      >
        {subtext}
      </a>
      <div className={styles.lineRight}></div>
    </div>
  </div>
)

export default Banner
