import { Icons } from '@lazy-app/design-system'

import { LauncherStaticCommand } from './launcherCommand.type'
import markDownListCommand from './launcherCommandList.cmd'
// import launcherContextCommand from './launcherContext.cmd'

export const launcherCommandParams: LauncherStaticCommand = {
  id: '/',
  icon: Icons.SLASH,
  keysRender: ['/'],
  name: 'Trailing menu',
  isDisabled: () => true,
  position: 10000,
  options: () => {
    return [
      ...markDownListCommand,
      // ...launcherContextCommand
    ]
  },
}
