import React, { FC } from 'react'

import { Icon, Icons, Shortcut } from '@lazy-app/design-system'
import {
  alpha,
  Box,
  Chip,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material'
import { SxProps } from '@mui/system'
import CommandIcon from './CommandIcon'

import { LauncherStaticCommand } from './launcherCommand/launcherCommand.type'

export type TrailingMenuItemType = {
  item: LauncherStaticCommand
  muiProps: React.HTMLAttributes<HTMLLIElement>
}

const trailingMenuItemStyles: SxProps<Theme> = {
  boxSizing: 'border-box',
  maxHeight: 32,
  p: 1,
  mx: 2,
  borderRadius: 2,
  boxShadow: 0,
  backgroundColor: 'transparent',
  position: 'relative',
  '&:hover': {
    background: 'none',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '18px',
    mr: 0.5,
  },
  '& .MuiListItemText-root .MuiTypography-root': {
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '18px',
    '& span': {
      fontFamily: 'Inter',
      border: '0.5px dashed rgba(184, 184, 184, 0.7)',
      borderRadius: '8px',
      maxWidth: '208px',
      display: 'inline-block',
      color: 'text.hint',
      px: 1,
      ml: 1,
    },
  },
  border: '0.5px solid transparent',
  '&[aria-selected="true"]': {
    backgroundColor: (theme) => alpha(theme.palette.grey['600'], 0.9),
    borderColor: (theme) => alpha(theme.palette.grey['300'], 0.9),
    '&:before': {
      content: '""',
      backgroundColor: '#C4C4C4',
      width: 3,
      height: 30,
      position: 'absolute',
      left: -18,
      boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
}

export const TrailingMenuItem: FC<TrailingMenuItemType> = ({ item, muiProps }) => (
  <MenuItem {...muiProps} key={item.id} sx={trailingMenuItemStyles} disabled={item.soon}>
    <ListItemIcon>
      <CommandIcon icon={item.icon} />
    </ListItemIcon>
    <ListItemText primaryTypographyProps={{ noWrap: true }}>
      {item.name}
      {item.secondName && (
        <Typography component="span" noWrap>
          {item.secondName}
        </Typography>
      )}
      {!item.secondName && item.secondNamePlaceholder && (
        <Typography component="span" noWrap>
          {item.secondNamePlaceholder}
        </Typography>
      )}
    </ListItemText>
    <ListItemSecondaryAction
      sx={{ position: 'unset', transform: 'none', display: 'flex', kbd: { fontFamily: 'Inter', fontSize: '10px' } }}
    >
      {item.keysRender ? <Shortcut keys={item.keysRender} separator="then" /> : <Box />}
      {item.options ? <Icon icon={Icons.ARROW_RIGHT} /> : <Box />}
      {item.soon ? <Chip size={'small'} label={'Soon'} className="ChipSoon" variant="tag" /> : <></>}
    </ListItemSecondaryAction>
  </MenuItem>
)

export default TrailingMenuItem
