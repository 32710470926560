import { useState, useEffect } from 'react'

export default () => {
  const [mouseMove, setMouseMove] = useState<boolean>(false)

  // TO DON'T AUTO SCROLL WHEN MOVING THE MOUSE
  useEffect(() => {
    const timeout = mouseMove ? setTimeout(() => setMouseMove(false), 500) : undefined
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [mouseMove, setMouseMove])

  return { setMouseMove, mouseMove }
}
