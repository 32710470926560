import React, { useEffect, useState } from 'react'
import styles from './JoinRequest.module.scss'
// import Image from 'next/image'
import cx from 'classnames'
import { EAppearAnimation, ETypography } from '@/interfaces'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'
import { INTERSECT_THRESHOLD } from '@/constants'
const RequestAccess = dynamic(() => import('@/components/RequestAccess/RequestAccess'), { ssr: false })

const JoinRequest = () => {
  const [appearOnce, setAppearOnce] = useState<boolean>(false)
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: INTERSECT_THRESHOLD,
  })

  // check if is inView
  useEffect(() => {
    if (inView) {
      setAppearOnce(true)
    }
  }, [inView])

  return (
    <div
      ref={ref}
      className={cx(styles.containerAppear, EAppearAnimation.bounce, {
        [EAppearAnimation.show]: appearOnce,
      })}
    >
      <div className={cx(styles.container)}>
        <div className={styles.glow}></div>
        <div className={styles.inner}>
          <div className={styles.texts}>
            <p className={cx(styles.text, ETypography.sh3)}>Don’t procrastinate to get Lazy.</p>
            <p className={cx(styles.subtext, ETypography.subtitle)}>
              Enter your email to get on the waitlist for Lazy access.
            </p>
          </div>
          <RequestAccess></RequestAccess>
        </div>
      </div>
    </div>
  )
}

export default JoinRequest
