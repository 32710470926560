import React, { FC } from 'react'

import { ListSubheader, Theme, Typography } from '@mui/material'
import { SxProps } from '@mui/system'

export type TrailingMenuGroupHeaderType = {
  title: string
}

const trailingMenuGroupHeaderStyles: SxProps<Theme> = {
  lineHeight: 'unset',
  display: 'flex',
  backgroundColor: 'transparent',
  mt: 1.5,
}

export const TrailingMenuGroupHeader: FC<TrailingMenuGroupHeaderType> = ({ title }) => (
  <ListSubheader title={title} disableSticky sx={trailingMenuGroupHeaderStyles}>
    <Typography variant={'overline'} pb={1} sx={{ color: 'text.hint', fontFamily: 'Inter', fontSize: '9px!important' }}>
      {title}
    </Typography>
  </ListSubheader>
)

export default TrailingMenuGroupHeader
