import { Color } from 'ogl-typescript'

export function hexToOGLColor(hex: string): Color {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? new Color(parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255)
    : null
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

/**
 * Linear interpolation function
 * must match primary colors available as css vars
 * @param start first value of the two numbers.
 * @param end second value of the two numbers.
 * @param amt amount by which a number is to be interpolated between the two numbers.
 */
export function lerp(start: number, end: number, amt: number): number {
  return (1 - amt) * start + amt * end
}

export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function randomFloat(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

/**
 * Round to a speicific decimal
 * @param num Number to round
 * @param decimal decimal
 */
export function roundTo(num: number, decimal: number): number {
  const round = Math.pow(10, decimal)
  return Math.round(num * round) / round
}

// Detect if device prioritize touch events
export function isTouchDevice() {
  if ('standalone' in navigator) {
    return true // iOS devices
  }
  const hasCoarse = window.matchMedia('(pointer: coarse)').matches
  if (hasCoarse) {
    return true
  }
  const hasPointer = window.matchMedia('(pointer: fine)').matches
  if (hasPointer) {
    return false // prioritize mouse control
  }

  // Otherwise, fall-back to older style mechanisms.
  return 'ontouchstart' in window
}
