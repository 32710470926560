import React, { useCallback, useEffect, useState } from 'react'
import styles from './ModalRequest.module.scss'
import cx from 'classnames'
import { ETypography } from '@/interfaces'
import ReplaySvg from '@/assets/icons/replay.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import launcherSlice from '@/store/reducers/launcher'
import dynamic from 'next/dynamic'
import { getEditorApi } from '@lazy-app/seshat'
import { ClipboardTextSerializer } from './textSerializer'
const RequestAccess = dynamic(() => import('@/components/RequestAccess/RequestAccess'), { ssr: false })

const ModalRequest = () => {
  const { modalRequest } = useSelector((state: RootState) => state.launcher)
  const dispatch = useDispatch<AppDispatch>()
  const [content, setContent] = useState<string | undefined>()

  const closeModal = useCallback(() => {
    dispatch(launcherSlice.actions.toggleModalRequest(false))
    dispatch(launcherSlice.actions.toggleReplayFlash(1))
  }, [dispatch])

  useEffect(() => {
    const editor = getEditorApi('launcher_editor')
    if (!editor?.view?.dom) return

    const serializer = new ClipboardTextSerializer()
    const fragment = editor.view.state.doc.content.content[0].content
    const content = serializer.serializeFragment(fragment)
    setContent(content)
  }, [modalRequest])

  return (
    <div className={cx(styles.container, { [styles.show]: modalRequest })}>
      <div className={styles.top}>
        <div className={cx(styles.topText, ETypography.subtitle)}>
          Request access to see your capture on the other side.
        </div>
        <ReplaySvg className={styles.replay} onClick={closeModal} />
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottom_container}>
          <RequestAccess autoFocus={modalRequest} content={content}></RequestAccess>
        </div>
      </div>
    </div>
  )
}

export default ModalRequest
