import Heading, { EHeadingType } from '@/components/Heading/Heading'
import React, { useEffect, useRef, useState } from 'react'
import styles from './ClipText.module.scss'
import Container from '@/components/Container/Container'
import DATA from '@/data/clipTextVideo'
import dynamic from 'next/dynamic'
import NavTitle from '@/components/NavTitle/NavTitle'
import { useScrollPosition } from 'hooks/useScrollPosition'

const ClipText = dynamic(() => import('@/components/ClipText/ClipText'), { ssr: false })

const SectionClipText = () => {
  const scrollPos = useScrollPosition()
  const [containerH, setContainerH] = useState<number>(0)
  const [innerH, setInnerH] = useState<number>(0)
  const [spriteFrame, setSpriteFrame] = useState<number>(1)

  const ref = useRef<HTMLDivElement>()
  const innerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    // set ref values
    if (containerH === 0) {
      setContainerH(ref.current.offsetHeight)
    }
    if (innerH === 0) {
      setInnerH(innerRef.current.offsetHeight)
    }
  }, [containerH, innerH])

  // get progress on scroll
  useEffect(() => {
    const parent = ref.current.parentNode as HTMLElement
    const top = (parent.parentNode as HTMLElement).offsetTop
    if (scrollPos > top && top > 0) {
      const percent = (scrollPos - top) / (containerH - innerH)
      if (percent < 1) {
        setSpriteFrame(Math.ceil(percent * DATA.video.frameCount))
      }
    }
  }, [scrollPos, containerH, innerH])

  return (
    <section className={styles.section}>
      <Container>
        <div ref={ref} className={styles.container}>
          <div ref={innerRef} className={styles.inner}>
            <NavTitle text={DATA.headingBanner} appear={true}></NavTitle>
            <Heading
              type={EHeadingType.h2}
              text={DATA.headingTitle}
              subtext={DATA.headingSubtitle}
              styleInline={{ marginBottom: '4.4rem' }}
            />
            <ClipText spriteFrame={spriteFrame} data={DATA}></ClipText>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SectionClipText
