import { useCallback } from 'react'

type UseNavInListProps = {
  listType: 'list' | 'grid' | 'sources' | 'people'
  setSelectedCardIndex: (index: number) => void
  cardLength: number
  selectedCardIndex: number
}

const useNavInList = ({ cardLength, setSelectedCardIndex, listType, selectedCardIndex }: UseNavInListProps) => {
  const previous = useCallback(() => {
    if (listType === 'grid') return
    let index = selectedCardIndex - 1
    if (selectedCardIndex <= 0) {
      index = cardLength - 1
    }
    setSelectedCardIndex(index)
  }, [cardLength, selectedCardIndex, setSelectedCardIndex, listType])
  const listKeyUp = previous

  const next = useCallback(() => {
    if (listType === 'grid') return
    let index = selectedCardIndex + 1
    if (selectedCardIndex >= cardLength - 1) {
      index = 0
    }
    setSelectedCardIndex(index)
  }, [cardLength, selectedCardIndex, setSelectedCardIndex, listType])
  const listKeyDown = next

  return { previous, next, listKeyDown, listKeyUp }
}

export default useNavInList
