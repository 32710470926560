import type { Fragment, Slice } from 'prosemirror-model'
import { domSerializerConfig } from './serializer.dom.config'

export class ClipboardTextSerializer {
  serializeFragment(fragment: Fragment) {
    let textContent = ''

    console.log(fragment)

    fragment.descendants((node, pos, parent) => {
      console.log({ node, parent })

      console.log(node.attrs.type)

      if (domSerializerConfig[node.attrs.type])
        textContent += domSerializerConfig[node.attrs.type]?.({ node, content: node.textContent }) + '\n'
    })

    return textContent
  }
  serializeSlice(slice: Slice) {
    return this.serializeFragment(slice.content)
  }
}
