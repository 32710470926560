import { FC } from 'react'

import { Box } from '@mui/material'
import CardLogoView from '../CardLogoView'

const TaskEditableCheckBox: FC = () => (
  <Box sx={{ mr: 0.5, display: 'flex', pt: '2px' }}>
    <CardLogoView card={{ type: 'task' }} />
  </Box>
)

export default TaskEditableCheckBox
