const DATA = {
  headingBanner: 'The end of context switching',
  headingTitle: 'Never interrupt your workflow again.',
  headingSubtitle: 'No need to switch apps to take notes.',
  video: {
    url: 'https://res.cloudinary.com/lazy-app/image/upload/v1666184876/landing-page/seq-highlights-desktop/Highlights_desktop-00',
    ext: 'png',
    width: 860,
    height: 665,
    frameCount: 191,
  },
  mobileVideo: {
    url: 'https://res.cloudinary.com/lazy-app/image/upload/v1666184823/landing-page/seq-highlights-mobile/Highlights_mobile-00',
    ext: 'png',
    width: 860,
    height: 665,
    frameCount: 191,
  },
}

export default DATA
