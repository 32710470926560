import React, { FC, useEffect, useState, useMemo, useCallback } from 'react'

import { Icons } from '@lazy-app/design-system'
import { Box, MenuList, Theme, alpha, Divider } from '@mui/material'
import { SxProps } from '@mui/system'
import { useDispatch } from 'react-redux'
import launcherCommandParams from './launcherCommand'
import { LauncherStaticCommand } from './launcherCommand/launcherCommand.type'
import { LauncherTrailingMenuSearchType } from './launcherCommand/LauncherMenuItem.type'
import TrailingMenuGroupHeader from './TrailingMenuGroupHeader'
import TrailingMenuItem from './TrailingMenuItem'
import useCmdKListAutoScroll from './useCmdKListAutoScroll'
import useSearchInList from './useSearchInList'
import useNavInList from './useNavInList'
import { useEditorApi, registerSuggestion } from '@lazy-app/seshat-react'
import { editorId } from '@/utils/TextEditor'

export const TRAILING_MENU_OPACITY = 0.9

const trailingMenuStyles: SxProps<Theme> = {
  width: 'calc(100% + 32px)',
  bgcolor: (theme) => alpha(theme.palette.background.default, TRAILING_MENU_OPACITY),
  backdropFilter: 'blur(10px)',
  transition: 'all 200ms',
  fontSize: '16px',
  ul: {
    marginBlockEnd: 0,
    overflowY: 'auto',
    maxHeight: '200px',
    transition: 'all 200ms',
    py: 0,
    pb: 1,
    '&:empty': {
      my: 0,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '& .MuiDivider-root': {
    width: 'calc(100% + 30px)',
    opacity: TRAILING_MENU_OPACITY,
  },
}

const DISABLED_KEYS_ON_SUGGESTION = ['Enter', 'ArrowDown', 'ArrowUp']

const getOptionProps = ({ index, option, selectedIndex, onMouseMove, onClick, ref }) => {
  return {
    key: option.id,
    tabIndex: -1,
    role: 'option',
    id: `${option.id}-option`,
    onMouseMove: (e: React.MouseEvent) => onMouseMove(e, index),
    onClick: (e: React.MouseEvent) => onClick(e, index),
    'data-option-index': index,
    'aria-disabled': false,
    'aria-selected': selectedIndex === index,
    ref,
  }
}

const TrailingMenu: FC = () => {
  const [search, setSearch] = useState<LauncherTrailingMenuSearchType | undefined>()
  const [list, setList] = useState<LauncherStaticCommand[]>([])
  const [keyEvent, setKeyEvent] = useState<KeyboardEvent | undefined>(undefined)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const dispatch = useDispatch()
  const { ref, selectedRef, setMouseMove } = useCmdKListAutoScroll({ selectedIndex, paddingTop: 50 })
  const searchInList = useSearchInList()
  const editorApi = useEditorApi(editorId)

  const options = useMemo(() => {
    return searchInList({ list: list as any, query: search?.query }).sort(
      (a, b) => a.position - b.position,
    ) as LauncherStaticCommand[]
  }, [list, search, searchInList])

  const groupedOptions = useMemo(() => {
    return options.reduce((acc, option, index) => {
      const group = option.group

      if (acc.length > 0 && acc[acc.length - 1].group === group) {
        acc[acc.length - 1].options.push(option)
      } else {
        acc.push({
          key: index,
          index,
          group,
          options: [option],
        })
      }

      return acc
    }, [] as { key: number; index: number; group?: string; options: any[] }[])
  }, [options])

  const { listKeyUp, listKeyDown } = useNavInList({
    cardLength: options.length,
    setSelectedCardIndex: setSelectedIndex,
    selectedCardIndex: selectedIndex,
    listType: 'list',
  })

  const handleEnter = useCallback(
    (index: number) => {
      const selectedOption = options[index]
      if (!selectedOption) return

      if (!!selectedOption.options) {
        setList(selectedOption.options())
        if (!editorApi) return false
        if (search) {
          return editorApi.commands.deleteRange({ from: search.range.from + 1, to: search.range.to })
        }
        editorApi.commands.deleteRange({ from: search.range.from + 1, to: search.range.to })
        setSelectedIndex(0)
      } else {
        search?.command({})
      }
      selectedOption?.callback?.({ dispatch })
    },
    [dispatch, editorApi, options, search],
  )

  useEffect(() => {
    if (keyEvent?.key === 'ArrowUp') listKeyUp()
    if (keyEvent?.key === 'ArrowDown') listKeyDown()
    if (keyEvent?.key === 'Enter') {
      handleEnter(selectedIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyEvent])

  useEffect(
    function setSelectedIndexToInitialPositionOnNewSearchString() {
      setSelectedIndex(0)
    },
    [search?.query],
  )

  useEffect(
    () => {
      registerSuggestion({
        editor: editorApi,
        onStart: setSearch,
        onUpdate: setSearch,
        chars: ['/'],
        onExit: () => setSearch(undefined),
        onKeyDown: ({ event }) => {
          if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
            event.preventDefault()
            event.stopPropagation()
          }
          setKeyEvent?.(event)
          return DISABLED_KEYS_ON_SUGGESTION.includes(event.key)
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorApi],
  )

  const isOpen = useMemo(
    () => !!(search?.text && (search?.text.length < 12 || groupedOptions.length)),
    [groupedOptions.length, search?.text],
  )

  useEffect(
    function setListOnOpenTrailingMenu() {
      if (!isOpen) setSelectedIndex(0)
      if (!search) return setList([])
      const commands = launcherCommandParams?.options?.()

      if (!commands) return setList([])
      setList(commands)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  )

  return (
    <Box id={'trailingMenu-combobox'} sx={trailingMenuStyles} onMouseMove={() => setMouseMove(true)}>
      {isOpen && (
        <>
          <Divider />
          <MenuList ref={ref}>
            {groupedOptions.map((group, index) => [
              <TrailingMenuGroupHeader title={group.group ?? ''} key={group.group + index} />,
              ...group.options.map((item, index) => (
                <TrailingMenuItem
                  key={item.id + index}
                  item={item}
                  muiProps={getOptionProps({
                    option: item,
                    index: group.index + index,
                    selectedIndex,
                    onMouseMove: (_, index) => {
                      setSelectedIndex(index)
                    },
                    onClick: (_, index) => {
                      handleEnter(index)
                    },
                    ref: selectedIndex === group.index + index ? selectedRef : undefined,
                  })}
                />
              )),
            ])}
          </MenuList>
        </>
      )}
      {isOpen && groupedOptions.length === 0 && (
        <TrailingMenuItem
          item={{ name: 'No results', id: 'no', search: 'no', icon: Icons.BLANK, position: 0 }}
          muiProps={{}}
        />
      )}
    </Box>
  )
}

export default TrailingMenu
