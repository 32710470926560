import React, { useCallback, useEffect, useRef } from 'react'
import styles from './CaptureBackground.module.scss'
import CaptureBackgroundScene from 'OGL/CaptureBackgroundScene'
import { useInView } from 'react-intersection-observer'

const CaptureBackground = () => {
  const OGLScene = useRef<CaptureBackgroundScene>()
  const ref = useRef()

  // check is in view
  const [inViewRef, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })
  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef],
  )

  // init scene
  useEffect(() => {
    OGLScene.current = new CaptureBackgroundScene(ref.current)
    return () => {
      if (OGLScene.current) OGLScene.current.destroy()
    }
  }, [])

  // toggle events if is inView
  useEffect(() => {
    if (OGLScene.current) {
      OGLScene.current.events(inView)
    }
  }, [inView])

  return <div ref={setRefs} className={styles.canvas}></div>
}

export default CaptureBackground
