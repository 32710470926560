import { TPointCoord } from '@/interfaces'
import { Geometry, Mesh, OGLRenderingContext, Program, Transform, Vec2 } from 'ogl-typescript'
import fragment from './connections.frag'
import vertex from './connections.vert'
import gsap from 'gsap'

const COUNT = 60
const COEF_LERP = 0.6
const ATTRACTION_EXT_RADIUS = 0.18
const ATTRACTION_INNER_RADIUS = 0.01
const ATTRACTION_COEF = 0.1
const MAX_VEL = 0.003
const TIMEOUT_ATTRACT = 1000 // ms

function distance(vec, distVec) {
  const a = vec.x - distVec.x
  const b = vec.y - distVec.y

  return Math.sqrt(a * a + b * b)
}

function rotate2D(cx: number, cy: number, x: number, y: number, angle: number): TPointCoord {
  const radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy
  return { x: nx, y: ny }
}

export default class ConnectionsMesh {
  gl: OGLRenderingContext
  particles: Mesh
  program: Program
  positions: Float32Array
  aging: Float32Array
  mouseTarget = {
    x: 0,
    y: 0,
  } as TPointCoord
  movement = {
    x: 0.1,
    y: 0.8,
  } as Vec2
  vel = {
    x: 0.001,
    y: 0.001,
  } as TPointCoord
  initVel = {
    x: 0,
    y: 0,
  } as TPointCoord
  initVelAngle = 2 * 0.1
  velAngle = 2 * 0.1
  scene: Transform
  canAttract = true
  canAttractTimeOut: ReturnType<typeof setTimeout>

  constructor({ scene, gl, initVel = { x: 0.003, y: 0 }, initVelAngle = 2 * 0.1, startPos = new Vec2(0.1, 0.8) }) {
    this.gl = gl
    this.scene = scene
    this.initVel = initVel
    this.vel = initVel
    this.initVelAngle = initVelAngle
    this.velAngle = initVelAngle
    this.movement = startPos

    this.init()
    this.particles.setParent(this.scene)
    this.particles.position.set(0, 0, 0)
  }

  init() {
    this.positions = new Float32Array(COUNT * 2)
    this.aging = new Float32Array(COUNT * 1)

    for (let i = 0; i < COUNT; i++) {
      this.positions.set([this.movement.x, this.movement.y], i * 2)
      this.aging.set([0], i * 1)
    }

    const geometry = new Geometry(this.gl, {
      position: { size: 2, data: this.positions },
      aging: { size: 1, data: this.aging },
    })

    this.program = new Program(this.gl, {
      vertex,
      fragment,
      uniforms: {
        uResolution: { value: new Vec2() },
        uPointSize: { value: 7 },
        uDPR: { value: window.devicePixelRatio },
      },
      transparent: true,
      depthTest: false,
    })

    // Make sure mode is gl.POINTS
    this.particles = new Mesh(this.gl, { mode: this.gl.POINTS, geometry, program: this.program })
  }

  resize = (w, h) => {
    this.program.uniforms.uResolution.value = new Vec2(w, h)
    this.program.uniforms.uDPR.value = window.devicePixelRatio
  }

  render() {
    const target = {
      x: this.movement.x + this.vel.x,
      y: this.movement.y + this.vel.y,
    }
    // update movment with velocity
    this.movement = new Vec2(target.x, target.y)

    // update and rotate velocity based on angle
    const newVel = rotate2D(0, 0, this.vel.x, this.vel.y, this.velAngle)
    this.vel = newVel

    // mouse attraction
    const distMouse = distance(this.mouseTarget, this.movement)

    if (this.canAttract) {
      if (distMouse < ATTRACTION_EXT_RADIUS) {
        // attract
        const newVel = {
          x: (this.mouseTarget.x - this.movement.x) * ATTRACTION_COEF,
          y: (this.mouseTarget.y - this.movement.y) * ATTRACTION_COEF,
        }
        // invert of
        if (Math.abs(newVel.x) < 0.005) {
          newVel.x = newVel.x * 2
        }
        if (Math.abs(newVel.y) < 0.005) {
          newVel.y = newVel.y * 2
        }
        this.vel = newVel

        if (distMouse < ATTRACTION_INNER_RADIUS) {
          this.canAttract = false
          clearTimeout(this.canAttractTimeOut)
          this.canAttractTimeOut = setTimeout(() => {
            this.canAttract = true
          }, TIMEOUT_ATTRACT)
        }
      }
    } else {
      if (distMouse > ATTRACTION_EXT_RADIUS) {
        this.canAttract = true
      }
    }

    // clamp vel
    this.vel.x = gsap.utils.clamp(-MAX_VEL, MAX_VEL, this.vel.x)
    this.vel.y = gsap.utils.clamp(-MAX_VEL, MAX_VEL, this.vel.y)

    // age and lerp particles to make a trail
    for (let i = COUNT - 1; i >= 0; i--) {
      const i2 = i * 2
      const previous = (i + 1) * 2

      if (i2 === (COUNT - 1) * 2) {
        // first particle
        this.positions[i2] = this.movement.x
        this.positions[i2 + 1] = this.movement.y
        this.aging[i2] = i
      } else {
        const currentPoint = new Vec2(this.positions[i2], this.positions[i2 + 1])

        const previousPoint = new Vec2(this.positions[previous], this.positions[previous + 1])

        currentPoint.lerp(previousPoint, COEF_LERP)

        this.positions[i2] = currentPoint.x
        this.positions[i2 + 1] = currentPoint.y
        this.aging[i] = 1 - i / COUNT

        if (i2 === 0) {
          // check if out of band
          if (
            this.positions[i2] < 0 ||
            this.positions[i2] > 1 ||
            this.positions[i2 + 1] < 0 ||
            this.positions[i2 + 1] > 1
          ) {
            this.resetPos({
              left: this.positions[i2] < 0,
              right: this.positions[i2] > 1,
              top: this.positions[i2 + 1] > 1,
              bottom: this.positions[i2 + 1] < 0,
            })
          }
        }
      }
    }
    this.particles.geometry.attributes.position.needsUpdate = true
    this.particles.geometry.attributes.aging.needsUpdate = true
  }

  resetPos({ left, right, top, bottom }) {
    // reset to left or right
    if (left) {
      this.movement.x = 1
      this.movement.y = Math.random()
      this.vel = { x: this.initVel.x > 0 ? -this.initVel.x : this.initVel.x, y: this.initVel.y }
      this.velAngle = -this.initVelAngle
    }
    if (right) {
      this.movement.x = 0
      this.movement.y = Math.random()
      this.vel = { x: this.initVel.x < 0 ? -this.initVel.x : this.initVel.x, y: this.initVel.y }
      this.velAngle = this.initVelAngle
    }
    if (top) {
      this.movement.x = Math.random()
      this.movement.y = 0
      this.vel = { x: this.initVel.x, y: -this.initVel.y }
      this.velAngle = this.initVelAngle
    }
    if (bottom) {
      this.movement.x = Math.random()
      this.movement.y = 1
      this.vel = { x: this.initVel.x, y: this.initVel.y }
      this.velAngle = this.initVelAngle
    }
    for (let i = COUNT - 1; i >= 0; i--) {
      const i2 = i * 2
      this.positions[i2] = this.movement.x
      this.positions[i2 + 1] = this.movement.y
    }
  }
}
