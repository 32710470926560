const DATA = {
  headingBanner: 'Lazy AI',
  headingBannerBold: 'Live on Product Hunt today!',
  headingTitleBold: 'Capture ',
  headingTitle: 'at the speed of thought',
  headingSubtitle: 'One keyboard shortcut to take notes & save information.',
  paragraphBold: 'Try the demo',
  paragraph: ' - Get a feel for how you can use Lazy to jot down something and start organizing your digital space.',
}

export default DATA
