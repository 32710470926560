import { Renderer, OGLRenderingContext, Camera, Transform, Color } from 'ogl-typescript'

import gsap from 'gsap'
import COLORS from '@/styles/config/_colors.module.scss'
import StarsMesh, { EStarsType } from './StarsMesh/StarsMesh'

export default class CaptureBackgroundScene {
  parent: HTMLDivElement
  renderer: Renderer
  gl: OGLRenderingContext
  raf: number
  eventStarted: boolean
  camera: Camera
  h = 0
  w = 0
  scene: Transform
  stars: StarsMesh
  lastY = 0

  constructor(parent) {
    this.parent = parent
    this.renderer = new Renderer({ depth: false })
    this.gl = this.renderer.gl
    parent.appendChild(this.gl.canvas)

    const bgColor = new Color(`${COLORS.black}`)

    this.gl.clearColor(bgColor[0], bgColor[1], bgColor[2], 1)

    this.scene = new Transform()

    this.camera = new Camera(this.gl)
    this.camera.orthographic()
    this.camera.position.z = 15
    this.stars = new StarsMesh({ scene: this.scene, gl: this.gl, type: EStarsType.captureBackground })

    this.handleResize()
    this.eventsStaying()
  }

  eventsStaying() {
    window.addEventListener('resize', this.handleResize, false)
  }

  events(toggle: boolean) {
    if (toggle) {
      if (this.eventStarted) return
      // start RAF
      gsap.ticker.add(this.handleRAF)
      this.eventStarted = true
    } else {
      gsap.ticker.remove(this.handleRAF)
      this.eventStarted = false
    }
  }

  handleResize = () => {
    const w = this.parent.offsetWidth * window.devicePixelRatio
    const h = this.parent.offsetHeight * window.devicePixelRatio
    this.h = this.parent.offsetHeight
    this.w = this.parent.offsetWidth
    this.renderer.setSize(w, h)
    this.camera.orthographic({ left: w / -2, right: w / 2, top: h / 2, bottom: h / -2, near: 0.1, far: 1000 })
    this.stars.resize(w, h)
  }

  handleRAF = (t: number) => {
    // this.stats.begin()
    this.stars.render(t)

    // Don't need a camera if camera uniforms aren't required
    this.renderer.render({ scene: this.scene, camera: this.camera })
    // this.stats.end()
  }

  destroy() {
    //
    window.removeEventListener('resize', this.handleResize, false)
    this.events(false)

    this.scene.removeChild(this.stars.particles)
    this.parent.removeChild(this.gl.canvas)
  }
}
