import { BlockTypes, SeshatNodes } from '@lazy-app/common'
import { Node as ProseMirrorNode } from 'prosemirror-model'

export type SerializerParams = {
  node: ProseMirrorNode
  content: string
  prevNode?: ProseMirrorNode
  nextNode?: ProseMirrorNode
}

/**
 * Here, copied blocks are turned in basic html easily interpretable by other editors (Notion, etc)
 * If no serializer is provided for a block, its content is wrapper in a DIV tag so it's interpreted as P in other editors.
 * Copy / paste from Seshat to Seshat does NOT use the serialized html since it uses the json Slice stored in the clipboard
 */
export const domSerializerConfig: Record<string, (args: SerializerParams) => string> = {
  [BlockTypes.PARAGRAPH]: ({ content }) => {
    return `<p>${content}</p>`
  },
  [BlockTypes.HEADING_1]: ({ content }) => {
    return `<h1>${content}</h1>`
  },
  [BlockTypes.HEADING_2]: ({ content }) => {
    return `<h2>${content}</h2>`
  },
  [BlockTypes.HEADING_3]: ({ content }) => {
    return `<h3>${content}</h3>`
  },
  [BlockTypes.HEADING_4]: ({ content }) => {
    return `<h4>${content}</h4>`
  },
  [BlockTypes.NUMBERED_LIST]: ({ content, prevNode, nextNode }) => {
    return `${prevNode?.attrs.type !== BlockTypes.NUMBERED_LIST ? '<ol>' : ''}<li>${content}</li>${
      nextNode?.attrs.type !== BlockTypes.NUMBERED_LIST ? '</ol>' : ''
    }`
  },
  [BlockTypes.BULLETED_LIST]: ({ content, prevNode, nextNode }) => {
    return `${prevNode?.attrs.type !== BlockTypes.BULLETED_LIST ? '<ul>' : ''}<li>${content}</li>${
      nextNode?.attrs.type !== BlockTypes.BULLETED_LIST ? '</ul>' : ''
    }`
  },
  [BlockTypes.BLOCKQUOTE]: ({ content }) => {
    return content ? `<blockquote>${content}</blockquote>` : ''
  },
  [BlockTypes.HIGHLIGHTS]: ({ content }) => {
    return `<div><strong>Highlights:</strong></div>${content}`
  },
  [BlockTypes.HIGHLIGHT]: ({ node, content }) => {
    return node.attrs.timestamp ? `<blockquote>${node.attrs.timestamp}</blockquote>${content}` : content
  },
  [SeshatNodes.CONTENT_NODE_VIEW]: ({ content }) => {
    return content
  },
  [SeshatNodes.CONTENT]: ({ content }) => {
    return content
  },
  [SeshatNodes.BLOCK_CONTENT]: ({ content }) => {
    return content
  },
  default: ({ content }) => {
    return `<div>${content}</div>`
  },
}
