import React, { useEffect, useState } from 'react'
import Heading, { EHeadingType } from '@/components/Heading/Heading'
import Banner from '@/components/Banner/Banner'
import Paragraph from '@/components/Paragraph/Paragraph'
import dynamic from 'next/dynamic'
import styles from './Intro.module.scss'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'
import DATA from '@/data/intro'
import Launcher from '@/components/Launcher/Launcher'

const IntroCanvas = dynamic(() => import('@/components/IntroCanvas/IntroCanvas'), { ssr: false })
// const Launcher = dynamic(() => import('@/components/Launcher/Launcher'), { ssr: false })

const SectionIntro = () => {
  const { appear, ready } = useSelector((state: RootState) => state.intro)
  const [fadeCanvas, setFadeCanvas] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeCanvas(true)
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <section className={styles.section}>
      <IntroCanvas appear={fadeCanvas}></IntroCanvas>

      {ready && ( // init elements after OGL scene is loaded so they don't appear first
        <>
          <Banner text={DATA.headingBanner} subtext={DATA.headingBannerBold} appear={appear}></Banner>
          <Heading
            type={EHeadingType.intro}
            text={DATA.headingTitleBold}
            subtext={DATA.headingTitle}
            description={DATA.headingSubtitle}
            appear={appear}
          />
          <Launcher appear={appear} />
          <Paragraph link={DATA.paragraphBold} text={DATA.paragraph} appear={appear}></Paragraph>
        </>
      )}
    </section>
  )
}

export default SectionIntro
