import { useCallback } from 'react'

import { LauncherStaticCommand } from './launcherCommand/launcherCommand.type'

const SLICE_NB = 30

const sliceList = (list: LauncherStaticCommand[]) => {
  if (list.length > SLICE_NB) {
    return list.slice(0, SLICE_NB)
  }

  return list
}

const useSearchInList = () => {
  const getList = useCallback(
    ({ list, query }: { list: LauncherStaticCommand[]; query?: string }): LauncherStaticCommand[] => {
      if (!query) return sliceList(list)
      const newFormatCmdKList = list
        .filter((cmd) => (cmd.name + cmd.search).toLocaleLowerCase().includes(query))
        .sort((a, b) => {
          if (a.isDisabled?.()) return -1
          return (b.name + b.search).toLocaleLowerCase().startsWith(query)
            ? -1
            : (a.name + a.search).toLocaleLowerCase().startsWith(query)
            ? 1
            : 0
        })

      return sliceList(newFormatCmdKList)
    },
    [],
  )

  return getList
}

export default useSearchInList
