import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import styles from './CaptureCircle.module.scss'
// import cx from 'classnames'
// import OuterCircleSvg from '@/assets/images/capture/outer-circle.svg'
import InnerCircleSvg from '@/assets/images/capture/inner-circle.svg'
import Logo from '@/assets/images/capture/logo.png'

import { TPointCoord } from '@/interfaces'
import { roundTo } from 'OGL/utils'

// item images
import Arc from '@/assets/images/capture/or-arc-orbit.png'
import Whatsapp from '@/assets/images/capture/or-whatsapp-orbit.png'
import Kindle from '@/assets/images/capture/or-kindle-orbit.png'
import Slack from '@/assets/images/capture/or-slack-orbit.png'
import Superhuman from '@/assets/images/capture/or-superhuman-orbit.png'
import Youtube from '@/assets/images/capture/or-youtube-orbit.png'
import Twitter from '@/assets/images/capture/or-twitter-orbit.png'
import PDF from '@/assets/images/capture/or-pdf-orbit.png'
import Spotify from '@/assets/images/capture/or-spotify-orbit.png'
import Mail from '@/assets/images/capture/or-mail-orbit.png'
import { useWindowSize } from '@react-hook/window-size'
// import gsap, { Power1 } from 'gsap'
import cx from 'classnames'
import { useInView } from 'react-intersection-observer'
import { INTERSECT_THRESHOLD } from '@/constants'

const NUMBER_ITEMS = 10

const ITEM_IMAGES = [
  Arc.src,
  Superhuman.src,
  Kindle.src,
  Slack.src,
  Twitter.src,
  Mail.src,
  Youtube.src,
  PDF.src,
  Spotify.src,
  Whatsapp.src,
]

const svgCoordToPx = (point: { x: number; y: number }, ratio: number): TPointCoord => {
  if (!point) {
    return {
      x: 0,
      y: 0,
    }
  }
  return { x: roundTo(point.x / ratio, 4), y: roundTo(point.y / ratio, 4) }
}

const namespaces = {
  xmlSpace: 'preserve',
}

type OuterCircleSvgProps = {
  setRef: React.LegacyRef<SVGSVGElement>
  setPathRef: React.RefObject<SVGPathElement>
}

const OuterCircleSvg = ({ setRef, setPathRef }: OuterCircleSvgProps) => (
  <svg ref={setRef} width="100%" height="100%" x="0px" y="0px" viewBox="0 0 423 366" {...namespaces}>
    <radialGradient id="SVGID_1_" cx="211.554" cy="183.183" r="99.1708" gradientUnits="userSpaceOnUse">
      <stop offset="1.838610e-03" style={{ stopColor: '#63656C', stopOpacity: 0.9913 }} />
      <stop offset="0.497" style={{ stopColor: '#303136' }} />
      <stop offset="0.9965" style={{ stopColor: '#303136' }} />
    </radialGradient>
    <path
      ref={setPathRef}
      style={{
        fill: 'none',
        stroke: 'url(#SVGID_1_)',
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeDasharray: '0.1,8',
      }}
      d="M126.3,58.4c106.2-72.6,230.5-75.6,277.6-6.7s-0.8,183.6-107,256.2s-230.5,75.6-277.6,6.7S20.1,131,126.3,58.4z
 "
    />
  </svg>
)

type TItem = {
  src: string
  x: number
  y: number
  active: boolean
}

type Props = {
  progress: number
}

const CaptureCircle = ({ progress }: Props) => {
  const containerRef = useRef<HTMLDivElement>()
  const pathRef = useRef<SVGPathElement>()
  const svgRef = useRef<SVGSVGElement>()
  // const timelineRef = useRef<gsap.core.Timeline>()
  const [pathLength, setPathLength] = useState<number>(0)
  const [svgRatio, setSvgRatio] = useState<number>(0)
  const [pathPoints, setPathPoints] = useState<Array<TPointCoord>>([])
  const [items, setItems] = useState<Array<TItem>>([])
  const [pointLightPos, setPointLightPos] = useState<TPointCoord>({ x: 0, y: 0 })
  const [logoActive, setLogoActive] = useState<boolean>(false)
  const [updateSizing, setUpdateSizing] = useState<boolean>(false)

  const [width] = useWindowSize()

  const [appearOnce, setAppearOnce] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setUpdateSizing(true)
      // wait for svg loaded
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // init svg
  useEffect(() => {
    const pathFillEl = pathRef.current
    const svgEl = svgRef.current
    const containerEl = containerRef.current

    const pathLength = Math.ceil(pathFillEl.getTotalLength())
    setPathLength(pathLength)

    const { width: vbWidth } = svgEl.viewBox.baseVal
    // const isLandscape = vbWidth > vbHeight
    const svgRatio = vbWidth / containerEl.offsetWidth
    setSvgRatio(svgRatio)
    const arr = []

    for (let i = 0; i < pathLength; i++) {
      const point = pathFillEl.getPointAtLength(pathLength - i) // invert rotation
      arr.push(point)
    }

    setPathPoints(arr)

    const pathPoint = svgCoordToPx(arr[0], svgRatio)
    setPointLightPos(pathPoint)
  }, [width, updateSizing])

  useEffect(() => {
    if (progress <= 0 || progress > 1) return
    const pathIndex = Math.floor(progress * pathLength)
    if (pathPoints[pathIndex]) {
      const pathPoint = svgCoordToPx(pathPoints[pathIndex], svgRatio)
      for (let i = 0; i < NUMBER_ITEMS; i++) {
        if (progress > i / NUMBER_ITEMS) {
          if (items[i]) {
            items[i].active = true
          }
        }
      }
      setPointLightPos(pathPoint)
    }
  }, [progress, items, pathLength, pathPoints, svgRatio])

  // // point light animation
  // const startAnim = useCallback(() => {
  //   const obj = {
  //     percent: 0,
  //   }
  //   setLogoActive(true)
  //   timelineRef.current = gsap.timeline()

  //   timelineRef.current.to(obj, {
  //     percent: 1,
  //     duration: 1.6,
  //     delay: 0.2,
  //     ease: Power1.easeInOut,
  //     onUpdate: () => {
  //       const pathIndex = Math.floor(obj.percent * pathLength)
  //       if (pathPoints[pathIndex]) {
  //         const pathPoint = svgCoordToPx(pathPoints[pathIndex], svgRatio)
  //         for (let i = 0; i < NUMBER_ITEMS; i++) {
  //           if (obj.percent > i / NUMBER_ITEMS) {
  //             if (items[i]) {
  //               items[i].active = true
  //             }
  //           }
  //         }
  //         setPointLightPos(pathPoint)
  //       }
  //     },
  //     onComplete: () => {
  //       const pathPoint = svgCoordToPx(pathPoints[0], svgRatio)
  //       setPointLightPos(pathPoint)
  //     },
  //   })
  // }, [pathLength, pathPoints, svgRatio, items])

  // point light animation
  // const resetAnim = useCallback(() => {
  //   timelineRef.current?.kill()
  //   setLogoActive(false)
  //   for (let i = 0; i < NUMBER_ITEMS; i++) {
  //     items[i].active = false
  //   }
  // }, [items])

  // after init svg, position items
  useEffect(() => {
    if (pathPoints.length > 0) {
      const arr = []
      for (let i = 0; i < NUMBER_ITEMS; i++) {
        const pathIndex = Math.ceil((i * pathLength) / NUMBER_ITEMS)
        const pathPoint = svgCoordToPx(pathPoints[pathIndex], svgRatio)
        const item = {
          src: ITEM_IMAGES[i],
          x: pathPoint.x,
          y: pathPoint.y,
        }
        arr.push(item)
      }
      setItems(arr)
    }
  }, [pathLength, pathPoints, svgRatio])

  // check is in view
  const [inViewRef, inView] = useInView({
    /* Optional options */
    threshold: INTERSECT_THRESHOLD,
  })
  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      containerRef.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef],
  )

  // start anim if ready + is in view
  useEffect(() => {
    if (appearOnce) return
    if (items.length > 0) {
      if (inView) {
        setLogoActive(true)
        setAppearOnce(true)
        // startAnim()
      } else {
        // resetAnim()
      }
    }
  }, [inView, items, appearOnce])

  return (
    <div ref={setRefs} className={styles.container}>
      <OuterCircleSvg setRef={svgRef} setPathRef={pathRef} />
      <InnerCircleSvg className={styles.innerCircle} />
      <div className={cx(styles.logoContainer, { [styles.active]: logoActive })}>
        <img className={cx(styles.logo)} src={Logo.src} />
      </div>
      {items.map((item, index) => (
        <img
          key={`item-${index}`}
          className={cx(styles.item, { [styles.active]: item.active })}
          src={item.src}
          style={{ left: item.x, top: item.y }}
        />
      ))}
      <div
        className={styles.pointLight}
        style={{
          transform: `translate(-50%, -50%) translate(${pointLightPos.x}px, ${pointLightPos.y}px) translateZ(0)`,
        }}
      ></div>
    </div>
  )
}

export default memo(CaptureCircle)
