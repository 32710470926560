const mentionConfig = {
  char: '[[',
  name: 'reference_bracket',
  placeholder: ' Link to card',
  icon: 'SEARCH' as const,
  searchField: 'title',
  fieldMentionId: 'uuid',
  generateNewMentionId: () => Math.random().toString(),
  getList: () => [],
  noOptionsText: 'Search for page',
  isDefault: true,
}
export default mentionConfig
