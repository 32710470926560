import { BlockTypes } from '@lazy-app/common'
import { getCurrentBlock } from '@lazy-app/seshat'
import { Editor } from '@tiptap/react'
import { EditorView } from 'prosemirror-view'

import { placeholders } from './placeholder.config'

export const getDefaultFontSize = () => 'var(--font-size-text-editor)'
const POSITION_CARET_FIRST_LINE = 4

const getPlaceholderForCurrentBlock = (editor: Editor) => {
  const element = getCurrentBlock(editor.state.doc, editor.state.selection.from)?.node

  const typeAttr = element?.attrs.type || element?.type.name
  const type = element?.type + (element?.attrs.level || '')

  return placeholders[typeAttr || type]
}

export type SelectionStyle = {
  lineHeight?: string
  padding?: string
  height?: string
  width?: string

  fontSize: string
}

export const getCurrentSelectionStyle = (): SelectionStyle => {
  const selectedDomElement = document.getSelection()?.anchorNode?.parentElement?.children[0]
  if (selectedDomElement) {
    const computeStyle = window.getComputedStyle(selectedDomElement)
    return {
      lineHeight: computeStyle.lineHeight,
      fontSize: computeStyle.fontSize,
      padding: computeStyle.padding,
      height: computeStyle.height,
      width: computeStyle.width,
    }
  }
  return { fontSize: getDefaultFontSize() }
}

const isNodeEmpty = (view: EditorView) => {
  const { selection } = view.state
  return !selection.$anchor.parent.textContent && !selection.$anchor.parent.childCount
}

export const getPlaceholderContent = ({ view, editor }: { editor: Editor; view: EditorView }) => {
  if (!isNodeEmpty(view)) return
  const placeholderForCurrentBlock = getPlaceholderForCurrentBlock(editor)

  return placeholderForCurrentBlock
}

export const isFirstLineEmpty = (editor: Editor) => {
  const { doc, selection } = editor.state
  const { from } = selection

  if (from !== POSITION_CARET_FIRST_LINE) return false
  if (!isNodeEmpty(editor.view)) return false

  return getCurrentBlock(doc, from)?.node.attrs.type === BlockTypes.PARAGRAPH
}
