import React, { FC, memo } from 'react'

import { Icon, Icons } from '@lazy-app/design-system'

type Props = {
  icon: Icons | React.ElementType
}

const isIcon = (icon: Props['icon']): icon is Icons => typeof icon === 'string'

const CommandIcon: FC<Props> = memo(function CommandIconMemo({ icon }: Props) {
  if (isIcon(icon)) return <Icon icon={icon} size={20} color="text.primary" />

  const CmdIcon = icon

  return <CmdIcon />
})

export default CommandIcon
