import { useMemo } from 'react'
import CardLinkView from './CardLinkView'

const NestedCardLink = ({ nodeAttrs }: { nodeAttrs?: { cardType: string; value?: string } }) => {
  const finalCard = useMemo(() => {
    return { type: nodeAttrs?.cardType ?? 'note' }
  }, [nodeAttrs?.cardType])

  return (
    <CardLinkView
      customTitle={nodeAttrs?.value}
      card={finalCard}
      hasNestedCards={false}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      variant="nestedCard"
    />
  )
}

export default NestedCardLink
