import { Renderer, OGLRenderingContext, Camera, Transform, Vec2, Color } from 'ogl-typescript'

import { TPointCoord } from '@/interfaces'
import ConnectionsMesh from './ConnectionsMesh/ConnectionsMesh'
import gsap from 'gsap'
import Stats from 'stats.js'
import COLORS from '@/styles/config/_colors.module.scss'
import StarsMesh from './StarsMesh/StarsMesh'
import { lerp } from './utils'

const COEF_MOUSE_LERP = 0.3

export default class ConnectionScene {
  parent: HTMLDivElement
  renderer: Renderer
  gl: OGLRenderingContext
  mouseTarget = {
    x: 0,
    y: 0,
  } as TPointCoord
  mouse = {
    x: 0,
    y: 0,
  } as TPointCoord
  raf: number
  eventStarted: boolean
  connectionsMeshes: Array<ConnectionsMesh>
  camera: Camera
  h = 0
  w = 0
  stats: Stats
  scene: Transform
  stars: StarsMesh
  lastY = 0
  scrollY = 0

  constructor(parent) {
    this.parent = parent
    this.renderer = new Renderer({ depth: false })
    this.gl = this.renderer.gl
    parent.appendChild(this.gl.canvas)

    const bgColor = new Color(`${COLORS.black}`)

    this.gl.clearColor(bgColor[0], bgColor[1], bgColor[2], 1)

    // this.stats = new Stats()
    // this.stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(this.stats.dom)

    this.scene = new Transform()

    this.camera = new Camera(this.gl)
    this.camera.orthographic()
    this.camera.position.z = 15

    this.connectionsMeshes = [
      new ConnectionsMesh({
        scene: this.scene,
        gl: this.gl,
        initVel: { x: 0.003, y: -0.003 },
        initVelAngle: -2 * 0.1,
        startPos: new Vec2(0.05, 0.6),
      }),
      new ConnectionsMesh({
        scene: this.scene,
        gl: this.gl,
        initVel: { x: -0.003, y: -0.003 },
        initVelAngle: 3 * 0.1,
        startPos: new Vec2(0.95, 0.3),
      }),
      new ConnectionsMesh({
        scene: this.scene,
        gl: this.gl,
        initVel: { x: -0.001, y: -0.003 },
        initVelAngle: -1 * 0.1,
        startPos: new Vec2(0.6, 0.95),
      }),
    ]
    this.stars = new StarsMesh({ scene: this.scene, gl: this.gl })

    this.handleResize()
    this.eventsStaying()
  }

  eventsStaying() {
    window.addEventListener('resize', this.handleResize, false)
  }

  events(toggle: boolean) {
    if (toggle) {
      if (this.eventStarted) return
      // start RAF
      gsap.ticker.add(this.handleRAF)
      this.eventStarted = true
    } else {
      gsap.ticker.remove(this.handleRAF)
      this.eventStarted = false
    }
  }

  handleScroll = (scrollY: number) => {
    this.scrollY = scrollY
    const scrollOffset = (this.parent.parentNode as HTMLDivElement).offsetTop - scrollY
    this.mouseTarget.y = 1 - (this.lastY - scrollOffset) / this.h
  }

  handleMouseMove = (e: TPointCoord) => {
    // EVENTS
    const x = e.x / this.w
    const scrollOffset = (this.parent.parentNode as HTMLDivElement).offsetTop - this.scrollY
    const y = 1 - (e.y - scrollOffset) / this.h
    this.lastY = e.y

    this.mouseTarget = {
      x,
      y,
    }
  }

  handleResize = () => {
    const w = this.parent.offsetWidth * window.devicePixelRatio
    const h = this.parent.offsetHeight * window.devicePixelRatio
    this.h = this.parent.offsetHeight
    this.w = this.parent.offsetWidth
    this.renderer.setSize(w, h)
    this.camera.orthographic({ left: w / -2, right: w / 2, top: h / 2, bottom: h / -2, near: 0.1, far: 1000 })
    for (let i = 0; i < this.connectionsMeshes.length; i++) {
      const connecMesh = this.connectionsMeshes[i]
      connecMesh.resize(w, h)
    }
    this.stars.resize(w, h)
  }

  handleRAF = (t: number) => {
    // this.stats.begin()
    for (let i = 0; i < this.connectionsMeshes.length; i++) {
      const connecMesh = this.connectionsMeshes[i]
      connecMesh.render()
    }

    this.mouse.x = lerp(this.mouse.x, this.mouseTarget.x, COEF_MOUSE_LERP)
    this.mouse.y = lerp(this.mouse.y, this.mouseTarget.y, COEF_MOUSE_LERP)

    for (let i = 0; i < this.connectionsMeshes.length; i++) {
      const connecMesh = this.connectionsMeshes[i]
      connecMesh.mouseTarget = this.mouse
    }
    this.stars.updateMouseTarget(this.mouse)

    this.stars.render(t)

    // Don't need a camera if camera uniforms aren't required
    this.renderer.render({ scene: this.scene, camera: this.camera })
    // this.stats.end()
  }

  destroy() {
    //
    window.removeEventListener('resize', this.handleResize, false)
    this.events(false)

    for (let i = 0; i < this.connectionsMeshes.length; i++) {
      const connecMesh = this.connectionsMeshes[i]
      this.scene.removeChild(connecMesh.particles)
    }
    this.scene.removeChild(this.stars.particles)
    this.parent.removeChild(this.gl.canvas)
  }
}
