import React from 'react'
import Heading, { EHeadingType } from '@/components/Heading/Heading'
import Container from '@/components/Container/Container'
import styles from './Articles.module.scss'
import VARIABLES from '@/styles/config/_variables.module.scss'
import DATA, { ESliderType } from '@/data/sliders'
import dynamic from 'next/dynamic'

const Slider = dynamic(() => import('@/components/Slider/Slider'), { ssr: false })

const SectionArticles = () => {
  return (
    <section className={styles.section}>
      <Container small styleInline={{ zIndex: VARIABLES.zIndexModalSup }}>
        <Heading
          type={EHeadingType.h2}
          text={DATA[ESliderType.articles].headingTitle}
          subtext={DATA[ESliderType.articles].headingSubtitle}
          left
          styleInline={{ marginBottom: '3rem' }}
        />
        <Slider type={ESliderType.articles}></Slider>
      </Container>
    </section>
  )
}

export default SectionArticles
