import React, { useMemo } from 'react'
import Join from 'sections/Join/Join'
import Intro from '@/sections/Intro/Intro'
import ClipText from '@/sections/ClipText/ClipText'
import Articles from '@/sections/Articles/Articles'
import Connections from '@/sections/Connections/Connections'
import Capture from '@/sections/Capture/Capture'
import WebClipper from '@/sections/WebClipper/WebClipper'
import Bidirectionnal from '@/sections/Bidirectionnal/Bidirectionnal'
import Members from '@/sections/Members/Members'
import JoinFooter from '@/sections/JoinFooter/JoinFooter'
import dynamic from 'next/dynamic'
const UniversalInbox = dynamic(() => import('@/sections/UniversalInbox/UniversalInbox'), { ssr: false })

export enum ESectionType {
  intro = 'intro',
  clipText = 'clipText',
  articles = 'articles',
  join = 'join',
  connections = 'connections',
  clipAnything = 'clipAnything',
  capture = 'capture',
  universalInbox = 'universalInbox',
  webClipper = 'webClipper',
  bidirectionnal = 'bidirectionnal',
  members = 'members',
  joinFooter = 'joinFooter',
}

type Props = {
  type?: ESectionType
}

const Section = ({ type }: Props) => {
  const [section] = useMemo(() => {
    let section
    switch (type) {
      case ESectionType.intro:
        section = <Intro />
        break
      case ESectionType.clipText:
        section = <ClipText />
        break
      case ESectionType.articles:
        section = <Articles />
        break
      case ESectionType.connections:
        section = <Connections />
        break
      case ESectionType.join:
        section = <Join />
        break
      case ESectionType.capture:
        section = <Capture />
        break
      case ESectionType.universalInbox:
        section = <UniversalInbox />
        break
      case ESectionType.webClipper:
        section = <WebClipper />
        break
      case ESectionType.bidirectionnal:
        section = <Bidirectionnal />
        break
      case ESectionType.members:
        section = <Members />
        break
      case ESectionType.joinFooter:
        section = <JoinFooter />
        break
      default:
        section = <Join />
        break
    }

    return [section]
  }, [type])

  return section
}

export default Section
