import Container from '@/components/Container/Container'
import React from 'react'
import styles from './Bidirectionnal.module.scss'
import dynamic from 'next/dynamic'
const GridBidirectionnal = dynamic(() => import('@/components/Grid/GridBidirectionnal'), { ssr: false })

const SectionBidirectionnal = () => {
  return (
    <section className={styles.section}>
      <Container small>
        <GridBidirectionnal></GridBidirectionnal>
      </Container>
    </section>
  )
}

export default SectionBidirectionnal
