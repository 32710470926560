import { EAppearAnimation } from '@/interfaces'
import cx from 'classnames'
import React from 'react'
import styles from './Paragraph.module.scss'

type Props = {
  text: string
  link?: string
  appear?: boolean
}

const Paragraph = ({ text, link, appear = true }: Props) => (
  <div
    className={cx(styles.paragraph, EAppearAnimation.fromBottom, EAppearAnimation.delay3, {
      [EAppearAnimation.show]: appear,
    })}
  >
    {link && <p className={styles.link}>{link}</p>}
    <p style={{ display: 'inline' }}>{text}</p>
  </div>
)

export default Paragraph
