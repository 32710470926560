import { Icon, Icons } from '@lazy-app/design-system'
import { Typography, Box } from '@mui/material'
import CardLogoView from './CardLogoView'

const ReferenceListItemView = ({ item }: { item: { type: string; title: string } }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', width: '100%' }} gap={1}>
    <Box sx={{ minWidth: '24px', justifyContent: 'center', display: 'flex', alignItems: 'center', pl: 0.25 }}>
      {!item.type ? (
        <Icon icon={Icons.CARD_ADD} size={20} sx={{ display: 'flex' }} />
      ) : (
        <CardLogoView card={item} size="20px" />
      )}
    </Box>
    <Typography noWrap sx={{ flex: 1, fontFamily: 'Inter', fontSize: '12px' }}>
      {item.title}
    </Typography>
  </Box>
)

export default ReferenceListItemView
