import { Icon, Icons } from '@lazy-app/design-system'
import { SxProps, Theme, Box, alpha } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import getCardIcon from './getCardIcon'
import TaskCheckBoxView from './TaskCheckBoxView'

export type CardLogoViewProps = {
  card: { type: string; emoji?: string; summarizeTitle?: string }
  hasNestedCards?: boolean
  arrowIcon?: boolean
  size?: string | number | undefined
}

const CONTAINER_PADDING = 2

// The outer container is only there to position the arrow icon
// because the inner container has an overflow: hidden due to the emoji's background styles
const outerContainerStyles: SystemStyleObject<Theme> = {
  position: 'relative',
  display: 'inline-flex',
  verticalAlign: 'middle',

  '.MuiSvgIcon-root': {
    // By default .MuiSvgIcon-root has a fontSize of 2.5rem
    // which makes the icons incompatible with fluid sizes and ems
    fontSize: '1em',
  },
}
const containerStyles: SystemStyleObject<Theme> = {
  display: 'inline-flex',
  borderRadius: '100px',
  padding: `${CONTAINER_PADDING}px`,
}
const createItemStyles = ({ size }: { size: string }): SxProps<Theme> => ({
  position: 'relative',
  height: size,
  width: size,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:not(:last-child)': {
    marginRight: '4px',
  },
})
const createExternalIconStyles = ({ size }: { size: string }): SxProps<Theme> => ({
  boxSizing: 'border-box',
  position: 'absolute',
  left: size,
  bottom: 0,
  transform: 'translateX(-100%)',
  width: `calc(${size} / 2)`,
  height: `calc(${size} / 2)`,
  borderRadius: '3px',
  backgroundColor: ({ palette }) => palette.background.default,
  border: ({ palette }) => `0.5px solid ${alpha(palette.common.white, 0.16)}`,

  '> *': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

export const CardLogoView = ({ card, size = '1.2em', arrowIcon }: CardLogoViewProps) => {
  const stringifiedSize = typeof size === 'number' ? `${size}px` : size
  // the container has a padding so in order to match the provided size,
  // its children must be scaled down by subtracting the padding from the size
  const innerItemSize = `calc(${stringifiedSize} - ${CONTAINER_PADDING * 2}px)`

  const itemStyles = createItemStyles({ size: innerItemSize })
  const externalIconStyles = createExternalIconStyles({ size: stringifiedSize })

  let icon: React.ReactElement | null = null
  if (card.type === 'task') {
    icon = <TaskCheckBoxView size={innerItemSize} />
  } else if (card.emoji == null) {
    const cardIcon = getCardIcon(card)
    if (cardIcon) {
      icon = <Icon icon={cardIcon} size={innerItemSize} />
    }
  }

  const icons = [
    icon,
    card.emoji && (
      <Box
        component="span"
        sx={{
          fontSize: `calc(${stringifiedSize} * 0.6)`,
        }}
      >
        {card.emoji}
      </Box>
    ),
  ]
    .filter(Boolean)
    .map((content, index) => (
      <Box key={index} component="span" sx={itemStyles}>
        {content}
      </Box>
    ))

  return (
    <Box component="span" sx={outerContainerStyles}>
      <Box component="span" sx={{ ...containerStyles }}>
        {icons}
      </Box>
      {arrowIcon && (
        <Box component="span" sx={externalIconStyles}>
          <Icon icon={Icons.ARROW_UP_RIGHT_LINK} size={`calc(${stringifiedSize} * 0.6)`} />
        </Box>
      )}
    </Box>
  )
}

export default CardLogoView
