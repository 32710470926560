import React, { useCallback, useEffect, useRef } from 'react'
import styles from './ConnectionsCanvas.module.scss'
import ConnectionsScene from 'OGL/ConnectionsScene'
import { useInView } from 'react-intersection-observer'
import { usePointerCoords } from 'hooks/usePointerCoords'
import { useScrollPosition } from 'hooks/useScrollPosition'

const ConnectionsCanvas = () => {
  const OGLScene = useRef<ConnectionsScene>()
  const ref = useRef()

  const scrollPos = useScrollPosition()
  const pointerMove = usePointerCoords()

  // check is in view
  const [inViewRef, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })
  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef],
  )

  // init scene
  useEffect(() => {
    OGLScene.current = new ConnectionsScene(ref.current)
    return () => {
      if (OGLScene.current) OGLScene.current.destroy()
    }
  }, [])

  // check if is inView
  useEffect(() => {
    if (OGLScene.current) {
      OGLScene.current.events(inView)
    }
  }, [inView])

  // on scroll
  useEffect(() => {
    if (OGLScene.current && inView) {
      OGLScene.current.handleScroll(scrollPos)
    }
  }, [scrollPos, inView])

  // on mouse move / pointer
  useEffect(() => {
    if (OGLScene.current && inView) {
      OGLScene.current.handleMouseMove(pointerMove)
    }
  }, [pointerMove, inView])

  return <div ref={setRefs} className={styles.canvas}></div>
}

export default ConnectionsCanvas
