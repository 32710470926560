import { SeshatEditor, useEditorApi, SeshatGlobalStyle } from '@lazy-app/seshat-react'
import { BlockAttrsParams, BlockTypes, SeshatNodes } from '@lazy-app/common'
import { FC } from 'react'
import { Shortcut } from '@lazy-app/design-system'
import styles from './Launcher.module.scss'

import { CacheProvider } from '@emotion/react'
import { theme } from '@lazy-app/design-system'
import { ThemeProvider, Box, GlobalStyles } from '@mui/material'
import { ThemeProvider as TempFixThemeProvider } from 'emotion-theming'
import { editorId } from '@/utils/TextEditor'
import TaskEditableCheckBox from './TextEditor/TaskEditableCheckBox/TaskEditableCheckBox'
import NestedCardLink from './TextEditor/NestedCardLink'
import ReferenceItem from './TextEditor/ReferenceItem'
import ReferenceListItemView from './TextEditor/ReferenceListItemView'
import mentionConfig from './TextEditor/mention.config'
import { muiCache, editorBoxStyles, editorGlobalStyles } from './Lazy.ds.style'
import { PlaceholderBallon } from './placeholder'

type Props = {
  onFocus: ({ editor }) => void
  onBlur: () => void
  onChange: () => void
  appear?: boolean
}

const content = [
  {
    type: SeshatNodes.WRAPPING_BLOCK,
    attrs: {
      type: 'capture',
      textAlign: 'left',
      [BlockAttrsParams.BLOCK_UUID]: null,
    },
    content: [
      {
        type: SeshatNodes.BLOCK,
        attrs: {
          type: 'paragraph',
          textAlign: 'left',
          [BlockAttrsParams.BLOCK_UUID]: null,
        },
        content: [
          {
            type: SeshatNodes.BLOCK_CONTENT,
            content: [
              {
                type: SeshatNodes.CONTENT,
              },
            ],
          },
        ],
      },
    ],
  },
]

const LauncherTextEditor: FC<Props> = ({ onFocus, onBlur, onChange, appear }) => {
  const editorApi = useEditorApi(editorId)

  if (!appear) return <div></div>
  return (
    <CacheProvider value={muiCache}>
      <TempFixThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <SeshatGlobalStyle />
          <GlobalStyles styles={editorGlobalStyles} />
          <Box sx={editorBoxStyles}>
            <div className={styles.editor}>
              {editorApi && (
                <PlaceholderBallon
                  editor={editorApi}
                  firstLinePlaceholder={{
                    tag: 'span',
                    content: (
                      <div className={styles.placeholder}>
                        Jot something down or hit <Shortcut keys="/" /> for lazy commands
                      </div>
                    ),
                  }}
                />
              )}
              <SeshatEditor
                editorId={editorId}
                content={content}
                options={{
                  extensionConfiguration: {
                    extensionVersion: 'launcher' as const,
                    apiUrl: 'https://lazy-odin.herokuapp.com',
                    wrapperComponentOverrides: {
                      launcher: {
                        [BlockTypes.TASK_EDITABLE]: TaskEditableCheckBox,
                      },
                    },
                    card: {
                      NestedCardLink: NestedCardLink,
                    },
                    mentions: {
                      MentionView: ReferenceItem as React.FC,
                      items: [
                        {
                          MentionItemListComponent: ReferenceListItemView,
                          ...mentionConfig,
                        },
                      ],
                    },
                  },
                  editorConfiguration: {
                    autofocus: false,
                    useFlushSync: true,
                    onFocus: onFocus,
                    onBlur: onBlur,
                    onUpdate: { fn: onChange },
                  },
                }}
              />
            </div>
          </Box>
        </ThemeProvider>
      </TempFixThemeProvider>
    </CacheProvider>
  )
}

export default LauncherTextEditor
