import React from 'react'

import { BlockTypes } from '@lazy-app/common'
import { Shortcut } from '@lazy-app/design-system'
import { Box } from '@mui/material'

export type PlaceholderType = {
  tag: React.ElementType
  content: string | React.ReactElement
}

export const placeholders: {
  [key: string]: PlaceholderType | false
} = {
  title: {
    tag: 'h1',
    content: 'Undefined',
  },
  [BlockTypes.HEADING_1]: {
    tag: 'h1',
    content: 'Heading 1',
  },
  [BlockTypes.HEADING_2]: {
    tag: 'h2',
    content: 'Heading 2',
  },
  [BlockTypes.HEADING_3]: {
    tag: 'h3',
    content: 'Heading 3',
  },
  [BlockTypes.HEADING_4]: {
    tag: 'h4',
    content: 'Heading 4',
  },
  [BlockTypes.BLOCKQUOTE]: {
    tag: 'div',
    content: 'Quote',
  },
  [BlockTypes.BULLETED_LIST]: {
    tag: 'div',
    content: 'Bullet List',
  },
  [BlockTypes.NUMBERED_LIST]: {
    tag: 'div',
    content: 'Ordered List',
  },
  [BlockTypes.TOGGLE_LIST]: {
    tag: 'div',
    content: 'Toggle List',
  },
  [BlockTypes.NODE_VIEW]: false,
  [BlockTypes.PARAGRAPH]: {
    tag: 'div',
    content: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        gap={0.5}
      >
        Hit
        <Shortcut keys="/" />
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          for lazy commands
        </Box>
      </Box>
    ),
  },
}
