import React, { useState } from 'react'

import { isCaretInEmptyClip } from '@lazy-app/seshat'
import Box from '@mui/material/Box'
import { Editor } from '@tiptap/react'
import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { PlaceholderType } from '../placeholder.config'
import {
  getCurrentSelectionStyle,
  getDefaultFontSize,
  getPlaceholderContent,
  isFirstLineEmpty,
  SelectionStyle,
} from '../placeholder.utils'
import { PlaceholderBallonHandler } from './PlaceholderBallonHandler'

export const PLACEHOLDER_PLUGIN_KEY = 'floating-placeholder-LP'

export const PlaceholderBallon = ({
  editor,
  firstLinePlaceholder,
}: {
  editor: Editor
  firstLinePlaceholder?: PlaceholderType
}) => {
  const [displayContent, setDisplayContent] = useState(false)
  const [selectionStyle, setSelectionStyle] = useState<SelectionStyle>({
    fontSize: getDefaultFontSize(),
  })
  const [placeholder, setPlaceholder] = useState<PlaceholderType | false>(firstLinePlaceholder)
  const [isFirstAppear, setIsFirstAppear] = useState(true)

  const showPlaceholder = (placeholder: PlaceholderType) => {
    setDisplayContent(true)
    setPlaceholder(placeholder)
    setSelectionStyle(getCurrentSelectionStyle())
    return true
  }

  const shouldShowHandle = ({
    view,
    editor,
    state,
    forceShow,
  }: {
    editor: Editor
    view: EditorView
    state: EditorState
    oldState?: EditorState
    forceShow?: boolean
  }) => {
    if (forceShow) {
      setDisplayContent(true)
      return
    }
    if (firstLinePlaceholder && isFirstLineEmpty(editor)) {
      return showPlaceholder(firstLinePlaceholder)
    }

    const placeholderContent = getPlaceholderContent({
      view,
      editor,
    })

    if (!placeholderContent || isCaretInEmptyClip(state)) {
      setDisplayContent(false)
      return false
    }

    return showPlaceholder(placeholderContent)
  }

  editor.on('focus', () => setIsFirstAppear(false))
  editor.on('blur', () => setIsFirstAppear(true))

  return (
    <div>
      <PlaceholderBallonHandler
        pluginKey={PLACEHOLDER_PLUGIN_KEY}
        tippyElementId={PLACEHOLDER_PLUGIN_KEY}
        editor={editor}
        shouldShow={shouldShowHandle}
        tippyOptions={{
          zIndex: 0,
        }}
      >
        {displayContent && placeholder && (
          <>
            {isFirstAppear && (
              <div
                style={{
                  width: '1px',
                  height: '20px',
                  backgroundColor: 'white',
                  display: 'block',
                  boxShadow: '0 0 10px white',
                  position: 'absolute',
                  left: '-10px',
                  top: '2px',
                  animation: 'blinker 1s linear infinite',
                }}
              />
            )}
            <Box
              component={placeholder.tag}
              sx={{
                ...selectionStyle,
                color: 'text.hint',
                userSelect: 'none',
                marginLeft: '-9.5px',
                display: 'flex',
                minHeight: '22px',
              }}
              contentEditable={false}
            >
              {placeholder.content}
            </Box>
          </>
        )}
      </PlaceholderBallonHandler>
    </div>
  )
}
