import React from 'react'

import Container from '@/components/Container/Container'
import styles from './JoinFooter.module.scss'
import { EHeadingType } from '@/components/Heading/Heading'

import VARIABLES from '@/styles/config/_variables.module.scss'
import { useWindowSize } from '@react-hook/window-size'
import dynamic from 'next/dynamic'
const RequestAccess = dynamic(() => import('@/components/RequestAccess/RequestAccess'), { ssr: false })
const Heading = dynamic(() => import('@/components/Heading/Heading'), { ssr: false })

const SectionJoinFooter = () => {
  const [width] = useWindowSize()

  return (
    <section className={styles.section}>
      <Container small>
        <div className={styles.inner}>
          <Heading
            type={EHeadingType.footer}
            left
            text="Start work smarter"
            subtext="Get Lazy"
            styleInline={{ marginBottom: width <= parseInt(VARIABLES.mobile) ? '20px' : null }}
          ></Heading>
          <RequestAccess ctaText="Request access" />
        </div>
      </Container>
    </section>
  )
}

export default SectionJoinFooter
