import ConnectionsCanvas from '@/components/ConnectionsCanvas/ConnectionsCanvas'
import Heading, { EHeadingType } from '@/components/Heading/Heading'
import React from 'react'
import styles from './Connections.module.scss'

const SectionConnections = () => {
  return (
    <section className={styles.section}>
      <ConnectionsCanvas />
      <Heading
        type={EHeadingType.h2}
        text="Let serendipity blossom."
        subtext="Your space to connect the dots."
      ></Heading>
    </section>
  )
}

export default SectionConnections
