// import penIcon from '@/assets/images/slider-cards/pen.png'
import portraitIcon from '@/assets/images/slider-cards/tiago.png'
import portraitIcon2 from '@/assets/images/slider-cards/shreyas.png'
import portraitIcon3 from '@/assets/images/slider-cards/anne-laure.png'
import portraitIcon4 from '@/assets/images/slider-cards/dan.png'
import portraitIcon5 from '@/assets/images/slider-cards/sonke.png'
import portraitIcon6 from '@/assets/images/slider-cards/cal.png'
import linkIcon from '@/assets/images/slider-cards/link.png'
import bookmarkIcon from '@/assets/images/slider-cards/bookmark.png'
import lockIcon from '@/assets/images/slider-cards/lock.png'
import bubbleIcon from '@/assets/images/slider-cards/bubble.png'
import clockIcon from '@/assets/images/slider-cards/clock.png'
import pen from '@/assets/images/slider-cards/pen.png'
import mail from '@/assets/images/slider-cards/mail.png'
import gem from '@/assets/images/slider-cards/gem.png'
import onePlace from '@/assets/images/slider-cards/one-place.png'
import sync from '@/assets/images/slider-cards/sync.png'

import ClipSvg from '@/assets/icons/clip.svg'
import TwitterSvg from '@/assets/icons/twitter.svg'
import YoutubeSvg from '@/assets/icons/youtube.svg'
// import KindleSvg from '@/assets/icons/kindle.svg'
import KindleWifiSvg from '@/assets/icons/kindle-wifi.svg'
import MobileSvg from '@/assets/icons/mobile.svg'
import NewsletterSvg from '@/assets/icons/newsletter.svg'

export enum ESliderType {
  articles = 'articles',
  webClipper = 'webClipper',
}
// to update a video go to the public folder in 'public https://d2pa4yfxmpz9zq.cloudfront.net/'
// add a folder containing all the images from 000 to 999 max
// add folderName/NameOfItem  then choose the correct extension in "ext"
// add the correct dimension corresponding to the image size
// add the correct amount of frame

const DATA = {
  [ESliderType.articles]: {
    headingTitle: 'Clip anything for later.',
    headingSubtitle: 'From articles to Twitter threads, videos & more.',
    sections: [
      {
        title: 'Articles',
        text: 'One shortcut: an article, a link, an author captured. Save to read later.',
        icon: ClipSvg,
        highlightBold: 'Save articles',
        highlight: 'and create compounding effects in the long run.',
        video: {
          url: 'https://res.cloudinary.com/lazy-app/video/upload/v1666874053/landing-page/videos_new/Clip_Article_Animation_new.mp4',
          dimensions: {
            width: 1444,
            height: 978,
          },
        },
        cards: [
          { icon: linkIcon.src, text: 'Save the link' },
          { icon: portraitIcon.src, text: 'Save author details' },
          { icon: pen.src, text: 'Add notes for your future self' },
          { icon: bookmarkIcon.src, text: 'Mark article as to-be-read later' },
        ],
      },
      {
        title: 'Twitter threads',
        text: 'Capture the best of Twitter. Read threads later even if the original tweet is deleted. No tweetbot publicly involved.',
        icon: TwitterSvg,
        highlightBold: 'Gather Twitter threads.',
        highlight: 'Capture signal through a never ending stream of noise.',
        video: {
          url: 'https://res.cloudinary.com/lazy-app/video/upload/v1666874057/landing-page/videos_new/lazy-thread-capture_new.mp4',
          dimensions: {
            width: 1444,
            height: 978,
          },
        },
        cards: [
          { icon: lockIcon.src, text: 'Save tweets in private' },
          { icon: portraitIcon2.src, text: 'Save author details' },
          { icon: pen.src, text: 'Add notes for your future self' },
          { icon: bookmarkIcon.src, text: 'Forge connections on the fly' },
        ],
      },
      {
        title: 'Youtube videos',
        text: 'Capture key points mentioned in videos and take actionable notes.',
        icon: YoutubeSvg,
        highlightBold: 'Capture key moments from videos.',
        highlight: 'Transcribe what you’ve just heard in one command.',
        video: {
          url: 'https://res.cloudinary.com/lazy-app/video/upload/v1666874062/landing-page/videos_new/Youtube_Lazy_V3_new.mp4',
          dimensions: {
            width: 1444,
            height: 978,
          },
        },
        cards: [
          { icon: clockIcon.src, text: 'Take timestamped notes' },
          { icon: portraitIcon3.src, text: 'Save author details' },
          { icon: bubbleIcon.src, text: 'Capture live transcript' },
          { icon: bookmarkIcon.src, text: 'Mark video as to-be-watched later' },
        ],
      },
    ],
  },
  [ESliderType.webClipper]: {
    headingTitle: 'Lazy is not just another web clipper.',
    headingSubtitle: 'Clip information from whatever app you’re working in.',
    sections: [
      {
        title: 'Newsletters',
        text: 'Save newsletters with one shortcut, no more forwarding to complicated email addresses.',
        // TODO update icon below
        icon: NewsletterSvg,
        highlightBold: 'Capture newsletters',
        highlight: 'and read on your schedule, not theirs.',
        video: {
          url: 'https://res.cloudinary.com/lazy-app/video/upload/v1666874045/landing-page/videos_new/Newsletter_Flow_V2_new.mp4',
          dimensions: {
            width: 1268,
            height: 860,
          },
        },
        cards: [
          { icon: mail.src, text: 'Save the email' },
          { icon: portraitIcon4.src, text: 'Save author details' },
          { icon: pen.src, text: 'Add notes for your future self' },
          { icon: bookmarkIcon.src, text: 'Mark email as to-be-read later' },
        ],
      },
      {
        title: 'Kindle',
        text: 'Automatically sync your highlights and notes from your Kindle.',
        // TODO update icon below
        icon: KindleWifiSvg,
        highlightBold: 'Unchain your',
        highlight: 'Kindle highlights.',
        reverseHighlight: true,
        video: {
          url: 'https://res.cloudinary.com/lazy-app/video/upload/v1666874041/landing-page/videos_new/Kindle_new.mp4',
          dimensions: {
            width: 1268,
            height: 860,
          },
        },
        cards: [
          { icon: sync.src, text: 'Auto sync' },
          { icon: portraitIcon5.src, text: 'Save author details' },
          { icon: onePlace.src, text: 'Highlights & notes in one place' },
          { icon: gem.src, text: 'Rediscover forgotten ideas' },
        ],
      },
      {
        title: 'Mobile',
        text: 'Capture ideas the instant you get them, whether you are on a computer or a mobile.',
        // TODO update icon below
        icon: MobileSvg,
        highlightBold: 'Mobile.',
        highlight: 'Capture as you go.',
        video: {
          url: 'https://res.cloudinary.com/lazy-app/video/upload/v1666874056/landing-page/videos_new/Mobile_Dynamic_Island_new.mp4',
          dimensions: {
            width: 1268,
            height: 860,
          },
        },
        cards: [
          { icon: linkIcon.src, text: 'Save articles, threads, PDFs' },
          { icon: portraitIcon6.src, text: 'Save author details' },
          { icon: pen.src, text: 'Capture your best ideas' },
          { icon: bookmarkIcon.src, text: 'Save highlights via camera' },
        ],
      },
    ],
  },
}

export default DATA
