import createCache from '@emotion/cache'
import { SxProps, Theme, tooltipClasses } from '@mui/material'

export const editorGlobalStyles = {
  '#language-popper': {
    '& input': {
      fontSize: '14px',
      lineHeight: '24px',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 10px)',
    },
  },
  '#convert-link-menu': {
    '.MuiMenuItem-root': {
      fontSize: '12px',
      lineHeight: '24px',
    },
  },
  [`.MuiTypography-root, .MuiOutlinedInput-input, .${tooltipClasses.tooltip}`]: {
    fontSize: '12px',
  },
}

export const editorBoxStyles: SxProps<Theme> = {
  '@keyframes blinker': {
    '50%': {
      opacity: 0,
    },
  },
  '.ProseMirror': {
    width: '100%',
    height: '100%',
    padding: '10px 15px 0 15px',
    cursor: 'text',
  },
  '*::-webkit-scrollbar': {
    display: 'none',
  },
  flex: 1,
  display: 'flex',
  position: 'relative',
  marginRight: '8px',
  '&  #seshat_container': {
    flex: 1,
    height: '100%',
    '& > div:nth-of-type(2)': {
      height: '100%',
      position: 'relative',
      '& .ProseMirror': {
        height: '100%',
        boxSizing: 'border-box',
      },
    },
  },
  '&:not(:first-of-type)': {
    mt: '40px',
    '& > .MuiBox-root': {
      pt: 0,
    },
  },
  '&  .MuiTypography-root, & .MuiTooltip-root, & .MuiTooltip-popper *, & .MuiButton-root': {
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  '& .seshat_block[type="seshatCardLink"] .MuiTypography-root': {
    font: 'inherit',
    fontSize: '16px',
  },
  ' & .reference .MuiTypography-root': {
    fontSize: '16px',
    fontFamily: 'Inter',
  },
  '& > .MuiBox-root': {
    boxSizing: 'border-box',
    maxHeight: 476,
    display: 'flex',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    scrollSnapType: 'y mandatory',
    flexDirection: 'column',
    flex: 1,
    '&::-webkit-scrollbar': {
      display: 'initial',
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 1,
      bgcolor: ({ palette }) => palette.grey['400'],
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 1,
      bgcolor: ({ palette }) => palette.grey['200'],
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: ({ palette }) => palette.grey['300'],
    },
    '&.Prosemirror': {
      paddingBottom: '50px',
    },
  },
  '.seshat_block[type="codeBlock"]': {
    '& .MuiButton-root .MuiBox-root': {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
}
export const muiCache = createCache({
  key: 'mui',
  prepend: true,
})
