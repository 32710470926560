import { memo, MouseEvent, useCallback } from 'react'

import type { CardStatusType } from '@lazy-app/common'
import { Icon, Icons } from '@lazy-app/design-system'
import { Box, styled } from '@mui/material'

type ContainerProps = {
  size: string
  disabled?: boolean
  percentage?: number
  status?: string
}

const Container = styled('span')<ContainerProps>(({ theme, size, disabled, status, percentage = 0 }) => ({
  height: size,
  width: size,
  borderRadius: '50%',
  overflow: 'hidden',
  background:
    'radial-gradient(39.58% 39.58% at 16.79% 14.58%, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.05)',
  border: `1px solid ${theme.palette.grey[50]}`,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: disabled ? 'auto' : 'pointer',
  position: 'relative',
  zIndex: 999,

  '& .MuiBox-root > .MuiSvgIcon-root': {
    width: '100%',
    height: '100%',
    opacity: status === 'done' ? 1 : 0,
    color: percentage > 0 ? theme.palette.grey[800] : '#fff',
  },
  '&:hover .MuiSvgIcon-root': {
    opacity: status !== 'done' && !disabled ? 0.6 : '',
  },
  '&:after': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    content: '"  "',
    background: `
    radial-gradient(39.58% 39.58% at 76.79% 24.58%, rgba(255, 255, 255, 0.2) -0%,rgb(255 0 0 / 0%) 100%),
    conic-gradient(rgba(37,38,42,0.9) ${100 - percentage}%, transparent 0%)`,
    borderRadius: '50px',
    transform: 'scaleX(-1)',
    transition: 'background .3s',
  },
}))

type ConicProps = {
  size: string
  percentage?: number
}

const Conic = styled('span')<ConicProps>(({ theme, size, percentage }) => ({
  position: 'absolute',
  height: `calc(${size} - ${parseInt(size) > 20 ? 6 : 4}px)`,
  width: `calc(${size} - ${parseInt(size) > 20 ? 6 : 4}px)`,
  background:
    percentage === 100
      ? theme.palette.text.primary
      : 'conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 0.2) -91.11deg, #FFFFFF 180.87deg, rgba(255, 255, 255, 0.2) 268.89deg, #FFFFFF 540.87deg)',
  borderRadius: '50%',
}))

type TaskCheckBoxViewProps = {
  onClick?: () => void
  size?: number | string
  status?: CardStatusType | undefined
  disabled?: boolean
  className?: string
  percentage?: number
}

// eslint-disable-next-line react/display-name
const TaskCheckBoxView = memo(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ({ onClick = () => {}, size = 18, status, disabled, className, percentage }: TaskCheckBoxViewProps) => {
    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        onClick()
      },
      [onClick],
    )

    const stringifiedSize = typeof size === 'number' ? `${size}px` : size

    return (
      <Container
        size={stringifiedSize}
        disabled={disabled}
        percentage={percentage}
        status={status || ''}
        onMouseDown={handleClick}
        className={className}
      >
        {!!percentage && <Conic size={stringifiedSize} percentage={percentage} />}
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Icon icon={Icons.CHECKBOX_HOVER} sx={{ zIndex: 9 }} />
        </Box>
      </Container>
    )
  },
)

export default TaskCheckBoxView
