import React from 'react'
import styles from './NavTitle.module.scss'
import cx from 'classnames'
import { EAppearAnimation, ETypography } from '@/interfaces'

type Props = {
  text: string
  appear?: boolean
}

const NavTitle = ({ text, appear }: Props) => (
  <div
    className={cx(styles.NavTitle, EAppearAnimation.fromBottom, {
      [EAppearAnimation.show]: appear,
    })}
  >
    <div className={styles.textWrapper}>
      <p className={cx(ETypography.small, styles.text)}>{text}</p>
    </div>
  </div>
)

export default NavTitle
