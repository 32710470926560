import { TPointCoord } from '@/interfaces'
import { Geometry, Mesh, OGLRenderingContext, Program, Transform, Vec2 } from 'ogl-typescript'
import fragment from './stars.frag'
import vertex from './stars.vert'

const COUNT = 30

export enum EStarsType {
  connections = 'connections',
  captureBackground = 'captureBackground',
}

export default class StarsMesh {
  gl: OGLRenderingContext
  particles: Mesh
  program: Program
  positions: Float32Array
  mouseTarget = {
    x: 0,
    y: 0,
  } as TPointCoord
  scene: Transform
  type: EStarsType
  count = COUNT

  constructor({ scene, gl, type = EStarsType.connections }) {
    this.gl = gl
    this.scene = scene
    this.type = type
    this.count = type === EStarsType.captureBackground ? 100 : 50

    this.init()
    this.particles.setParent(this.scene)
    this.particles.position.set(0, 0, 0)
  }

  init() {
    this.positions = new Float32Array(this.count * 2)
    const index = new Float32Array(this.count)

    for (let i = 0; i < this.count; i++) {
      this.positions.set([Math.random(), Math.random()], i * 2)
      index.set([i], i)
    }

    const geometry = new Geometry(this.gl, {
      position: { size: 2, data: this.positions },
      aIndex: { size: 1, data: index },
    })

    this.program = new Program(this.gl, {
      vertex,
      fragment,
      uniforms: {
        uResolution: { value: new Vec2() },
        uPointSize: { value: 3 },
        uTime: { value: 0 },
        uMouseTarget: { value: new Vec2() },
        uDPR: { value: window.devicePixelRatio },
        uNoMouse: { value: this.type === EStarsType.captureBackground ? 1 : 0 },
      },
      transparent: true,
      depthTest: false,
    })

    // Make sure mode is gl.POINTS
    this.particles = new Mesh(this.gl, { mode: this.gl.POINTS, geometry, program: this.program })
  }

  updateMouseTarget({ x, y }) {
    this.program.uniforms.uMouseTarget.value = new Vec2(x, y)
  }

  resize = (w, h) => {
    this.program.uniforms.uResolution.value = new Vec2(w, h)
    this.program.uniforms.uDPR.value = window.devicePixelRatio
  }

  render(t: number) {
    this.program.uniforms.uTime.value = t * 1.5
  }
}
