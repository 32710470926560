import profil1Img from '@/assets/images/members/profil-1.png'
import profil2Img from '@/assets/images/members/profil-vic.png'
import profil3Img from '@/assets/images/members/profil-krishna.png'
import profil4Img from '@/assets/images/members/profil-ismael.png'

const DATA = [
  {
    url: profil1Img.src,
    username: 'Shomik Ghosh',
    tag: '@shomikghosh21',
    text: 'Playing w/ <span>@lazyappHQ</span> beta & have to say it is quickly becoming a daily part of my workflow! Easiest way to capture quotes, context, articles, tweets all in an intuitive inbox format with a connected graph underlying it all to link concepts & ideas.',
  },
  {
    url: profil2Img.src,
    username: 'Vic Montano',
    tag: '@thevicmontano',
    text: '<span>@lazyappHQ</span> If you want to boost your digital content capture process and the organization of your second brain! 🔥<br /><br /><br />',
  },
  {
    url: profil3Img.src,
    username: 'KRISHNA AR',
    tag: '@krishnaar21',
    text: 'Recently onboarded to try out <span>@lazyappHQ</span> pretty much solves most of note taking problems from multiple sources. Yet to dive deep into it.<br /><br /><br />',
  },
  {
    url: profil4Img.src,
    username: 'Ismail Pelaseyed',
    tag: '@pelaseyed',
    text: 'This will change my life <span>@lazyappHQ</span> <br /><br /><span>#ProductivityHacks</span><br /><br />',
  },
]

export default DATA
