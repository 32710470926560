import { Box, Theme, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import CardLogoView, { CardLogoViewProps } from './CardLogoView'

export type CardLinkViewProps = {
  customTitle: string
  card: CardLogoViewProps['card']
  hasNestedCards?: boolean
  variant?: keyof StyleVariant
  onOpen: () => void
  maxWidth?: any
  arrowIcon?: boolean
  noLogo?: boolean
}

const sxParagraph: SxProps<Theme> = {
  color: 'currentColor',
  borderBottom: '1px solid',
  borderColor: 'currentColor',
  fontSize: '1em',
  '&:nth-of-type(2)': {
    marginLeft: '4px',
  },
}

type StyleVariantItem = {
  box: { sx: SxProps }
  paragraph: { sx: SxProps }
}

type StyleVariant = Record<'default' | 'referenceLink' | 'nestedCard', StyleVariantItem>

const styleVariant: StyleVariant = {
  referenceLink: {
    box: {
      sx: {
        fontFamily: 'Inter',
      },
    },
    paragraph: {
      sx: {
        whiteSpace: 'break-spaces',
        fontFamily: 'Inter',
      },
    },
  },
  nestedCard: {
    box: {
      sx: {
        display: 'block',
        width: '100%',
        fontFamily: 'Inter',
      },
    },
    paragraph: {
      sx: {
        whiteSpace: 'break-spaces',
        fontFamily: 'Inter',
      },
    },
  },
  default: {
    box: {
      sx: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    paragraph: {
      sx: {
        fontFamily: 'Inter',
      },
    },
  },
}

const CardLinkView = ({
  customTitle,
  card,
  hasNestedCards,
  onOpen,
  maxWidth = 'none',
  variant = 'default',
  arrowIcon,
  noLogo,
}: CardLinkViewProps) => {
  return (
    <Box
      contentEditable={false}
      component="span"
      sx={{
        cursor: 'pointer',
        color: 'text.secondary',
        position: 'relative',
        // .hover is used for hover related context indicator on CardReferenceContext
        '&:hover, &.hover': {
          color: 'common.white',
        },
        ...styleVariant[variant].box.sx,
      }}
    >
      {!noLogo && <CardLogoView card={card} arrowIcon={arrowIcon} hasNestedCards={hasNestedCards} size={20} />}
      <Typography
        data-card-link={Math.random().toString()} // needed to open the card view via keyboard shortcuts
        onClick={onOpen}
        contentEditable={false}
        component="span"
        sx={{ ...sxParagraph, ...styleVariant[variant].paragraph.sx, maxWidth }}
        noWrap
      >
        {customTitle}
      </Typography>
    </Box>
  )
}

export default CardLinkView
