import Section, { ESectionType } from '@/components/Section/Section'
import Header from '@/components/Header/Header'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import Footer from '@/components/Footer/Footer'
import ModalFull from '@/components/ModalFull/ModalFull'
import Layout from '@/components/Layout/Layout'
import launcherSlice from '@/store/reducers/launcher'
import introSlice from '@/store/reducers/intro'

import METADATA from '@/data/metadata'
// import BuildingPage from '@/components/BuildingPage/BuildingPage'

const IndexPage = () => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    return () => {
      dispatch(introSlice.actions.setReady(false))
      dispatch(introSlice.actions.setAppear(false))
      dispatch(launcherSlice.actions.clearAll({}))
    }
  }, [dispatch])

  // if (buildingPage) {
  //   return (
  //     <Layout {...METADATA}>
  //       <BuildingPage />
  //     </Layout>
  //   )
  // }

  return (
    <Layout {...METADATA}>
      <Header />
      <Section type={ESectionType.intro}></Section>
      <Section type={ESectionType.clipText}></Section>
      <Section type={ESectionType.articles}></Section>
      <Section type={ESectionType.join}></Section>
      <Section type={ESectionType.capture}></Section>
      <Section type={ESectionType.universalInbox}></Section>
      <Section type={ESectionType.webClipper}></Section>
      <Section type={ESectionType.connections}></Section>
      <Section type={ESectionType.bidirectionnal}></Section>
      <Section type={ESectionType.members}></Section>
      <Section type={ESectionType.joinFooter}></Section>
      <ModalFull />
      <Footer />
    </Layout>
  )
}

export default IndexPage
