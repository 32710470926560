import React from 'react'
import Heading, { EHeadingType } from '@/components/Heading/Heading'
import Container from '@/components/Container/Container'
import styles from './WebClipper.module.scss'
import DATA, { ESliderType } from '@/data/sliders'
import dynamic from 'next/dynamic'

const Slider = dynamic(() => import('@/components/Slider/Slider'), { ssr: false })

const SectionWebClipper = () => {
  return (
    <section className={styles.section}>
      <Container small>
        <Heading
          type={EHeadingType.h2}
          text={DATA[ESliderType.webClipper].headingTitle}
          subtext={DATA[ESliderType.webClipper].headingSubtitle}
          left
          styleInline={{ marginBottom: '3rem' }}
        />
        <Slider type={ESliderType.webClipper}></Slider>
      </Container>
    </section>
  )
}

export default SectionWebClipper
