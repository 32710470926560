const DATA = {
  title: 'Lazy • A capture tool for knowledge',
  description: 'One keyboard shortcut to save every idea, link, tweet and so much more',
  url: 'https://lazy.so',
  socialImgOg: 'https://lazy.so/images/lazy-og.jpg?4362984378',
  socialImgTwitter: 'https://lazy.so/images/lazy-og.jpg?4362984378',
  GTAG_ID: 'G-M4T0GNKCNR',
}

export default DATA
