import * as React from 'react'
import { useRef } from 'react'

import useMouseMoveState from './useMouseMoveState'

export default ({
  selectedIndex,
  paddingTop = 0,
}: {
  selectedIndex: number
  paddingTop?: number
}): {
  ref: React.RefObject<any>
  selectedRef: React.RefObject<any>
  setMouseMove: (isMoving: boolean) => void
} => {
  const ref = useRef<any>(null)
  const selectedRef = useRef<any>(null)
  const { setMouseMove, mouseMove } = useMouseMoveState()
  // AUTO SCROLL
  React.useEffect(
    () => {
      if (ref.current && selectedRef.current && !mouseMove) {
        const scrollTop =
          ref.current.offsetHeight < selectedRef.current.offsetTop + paddingTop
            ? selectedRef.current.offsetTop - ref.current.offsetHeight + paddingTop
            : 0
        ref.current.scrollTo({
          top: scrollTop,
          left: 0,
          behavior: 'smooth',
        })
      }
    }, // Don't put list in it it make an infinity loop
    // eslint-disable-next-line
    [selectedRef, ref, selectedIndex],
  )

  return { ref, selectedRef, setMouseMove }
}
