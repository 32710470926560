import ReferenceItemView from './ReferenceItemView'

export type ReferenceItemType = {
  node: { attrs?: { value?: string; cardType?: string } }
  updateAttributes: (attrs: { [key: string]: any }) => void
  char?: string
}

const ReferenceItem = ({ node }: ReferenceItemType) => {
  return (
    <ReferenceItemView
      card={{ summarizeTitle: node.attrs?.value, type: node.attrs?.cardType ?? 'note' }}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      customTitle={node.attrs?.value}
    />
  )
}

export default ReferenceItem
