import React from 'react'

import JoinRequest from '@/components/JoinRequest/JoinRequest'
import Container from '@/components/Container/Container'
import styles from './Join.module.scss'
import bkgImg from '@/assets/images/grain-bkg.png'

const SectionExplore = () => {
  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <img className={styles.bkg} src={bkgImg.src} alt="" />
      </div>
      <Container>
        <JoinRequest />
      </Container>
    </section>
  )
}

export default SectionExplore
