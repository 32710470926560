import Heading, { EHeadingType } from '@/components/Heading/Heading'
import React from 'react'
import styles from './Members.module.scss'
import Members from '@/components/Members/Members'

const SectionMembers = () => {
  return (
    <section className={styles.section}>
      <Heading type={EHeadingType.h2} text="Wise words." subtext="Read our pioneers reactions."></Heading>
      <Members />
    </section>
  )
}

export default SectionMembers
