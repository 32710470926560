import CaptureBackground from '@/components/CaptureCircle/CaptureBackground/CaptureBackground'
import CaptureCircle from '@/components/CaptureCircle/CaptureCircle'
import Heading, { EHeadingType } from '@/components/Heading/Heading'
import { useWindowSize } from '@react-hook/window-size'
import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './Capture.module.scss'
import NavTitle from '@/components/NavTitle/NavTitle'
import { useScrollPosition } from 'hooks/useScrollPosition'

const SectionCapture = () => {
  const scrollPos = useScrollPosition()
  const [width, height] = useWindowSize()
  const [containerH, setContainerH] = useState<number>(0)
  const [innerH, setInnerH] = useState<number>(0)
  const [progress, setProgress] = useState<number>(0)

  const ref = useRef<HTMLDivElement>()
  const innerRef = useRef<HTMLDivElement>()

  const { ref: inViewRef, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    // set ref values
    if (containerH === 0) {
      setContainerH(ref.current.offsetHeight)
    }
    if (innerH === 0) {
      setInnerH(innerRef.current.offsetHeight)
    }
  }, [containerH, innerH, width, height])

  // get progress on scroll
  useEffect(() => {
    if (!inView) return
    // manual sticky positionning

    const vw = ref.current.getBoundingClientRect()

    // progress sprite and separation

    if (-vw.top > 0) {
      const percent = -vw.top / (containerH - innerH)

      if (percent < 1) {
        setProgress(percent)
      }
    }
  }, [scrollPos, containerH, innerH, width, height, inView])

  return (
    <section ref={inViewRef} className={styles.section}>
      <div ref={ref} className={styles.content}>
        <div ref={innerRef} className={styles.inner}>
          <NavTitle text="Universal clipper" appear={true}></NavTitle>
          <Heading
            type={EHeadingType.h2}
            text="Capture anything from anywhere."
            subtext="Lazy works wherever you are."
            styleInline={{ zIndex: 1000, position: 'relative' }}
          />
          <CaptureCircle progress={progress} />
        </div>
      </div>
      <CaptureBackground />
    </section>
  )
}

export default SectionCapture
