import { Icons } from '@lazy-app/design-system'

const getCardIcon = (card: { type: string }) => {
  switch (card.type) {
    case 'inbox':
      return Icons.INBOX
    case 'note':
      return Icons.CARD_EMPTY
    case 'capture':
      return Icons.FLASH
    case 'task':
      return Icons.CHECK_OFF_CIRCLE
    case 'day':
      return Icons.JOURNAL
    case 'people':
      return Icons.PEOPLE
    case 'sources':
      return Icons.DATABASE
    case 'journal':
      return Icons.JOURNAL
    case 'trash':
      return Icons.TRASH
    default:
      return undefined
  }
}
export default getCardIcon
