import { Icons, Key } from '@lazy-app/design-system'
import { getEditorApi } from '@lazy-app/seshat'
import { BlockTypes } from '@lazy-app/common'

import { LauncherStaticCommand } from './launcherCommand.type'

const updateTextEditorBlockAttributes = ({ editorId, attrs }: { editorId?: string; attrs: Partial<any> }) => {
  const editor = getEditorApi(editorId)
  return editor?.commands.updateBlockAttributes(attrs)
}

const launcherCommandList: LauncherStaticCommand[] = [
  {
    id: '/paragraph',
    search: 'Text',
    name: 'Text',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '0'],
    icon: Icons.TEXT1,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'paragraph' } }),
    position: 1000,
  },
  {
    id: '/heading1',
    search: 'Heading 1 Title',
    name: 'Heading 1',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '1'],
    icon: Icons.H1,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'heading1' } }),
    position: 1020,
  },
  {
    id: '/heading2',
    search: 'Heading 2 SubTitle',
    name: 'Heading 2',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '2'],
    icon: Icons.H2,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'heading2' } }),
    position: 1030,
  },
  {
    id: '/heading3',
    search: 'Heading 3 SubTitle',
    name: 'Heading 3',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '3'],
    icon: Icons.H3,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'heading3' } }),
    position: 1040,
  },
  {
    id: '/task',
    search: 'tasks todo',
    name: 'Task',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '4'],
    icon: Icons.TODOLIST,
    callback: () =>
      updateTextEditorBlockAttributes({
        attrs: { type: BlockTypes.TASK_EDITABLE },
      }),
    position: 1040,
  },
  {
    id: '/bulletedList',
    search: 'Bulleted List',
    name: 'Bulleted List',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '5'],
    icon: Icons.UNORDERED_LIST,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'bulletedList' } }),
    position: 1050,
  },
  {
    id: '/numberedList',
    search: 'Numbered List',
    name: 'Numbered List',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '6'],
    icon: Icons.ORDERED_LIST,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'numberedList' } }),
    position: 1060,
  },
  {
    id: '/code',
    search: 'codeblock code-block code block',
    name: 'Code',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '7'],
    icon: Icons.CODE,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: BlockTypes.CODE } }),
    position: 1060,
  },
  {
    id: '/quote',
    search: 'Quote',
    name: 'Quote',
    group: 'style',
    keysRender: [Key.COMMAND, Key.OPTION, '8'],
    icon: Icons.QUOTE,
    callback: () => updateTextEditorBlockAttributes({ attrs: { type: 'blockquote' } }),
    position: 1061,
  },
  {
    id: '/divider',
    search: 'Divider',
    name: 'Divider',
    group: 'style',
    icon: Icons.DIVIDER_VERTICAL,
    callback: () => {
      const editor = getEditorApi()
      // @ts-ignore
      editor?.chain().focus().setHorizontalRule().run()
    },
    position: 1070,
  },
  {
    id: '/makeConnection',
    search: 'Make connection bi bidirectional link reference',
    name: 'Make connection',
    group: 'style',
    keysRender: ['[', '['],
    icon: Icons.CONNECTION,
    callback: () => {
      const editor = getEditorApi()
      if (!editor) return
      // @ts-ignore
      return editor?.chain().insertMentionPlaceholderOnTextSelection({}).run()
    },
    position: 900,
  },
]

export default launcherCommandList
