import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './Launcher.module.scss'
import CapturesSvg from '@/assets/icons/captures.svg'
import LinkSvg from '@/assets/icons/link.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import launcherSlice from '@/store/reducers/launcher'
import { useWindowSize } from '@react-hook/window-size'
import { lerp } from 'OGL/utils'
import gsap from 'gsap'
import cx from 'classnames'
import ModalRequest from '@/components/ModalRequest/ModalRequest'
import ModalTry from '@/components/ModalTry/ModalTry'
import { useInView } from 'react-intersection-observer'
import { EAppearAnimation, ETypography, TPointCoord } from '@/interfaces'
import LauncherTextEditor from './LauncherTextEditor'
import Button from '@/components/Button/Button'
import { focusEditor, getEditor, isInEdition } from '@/utils/TextEditor'
import { TrailingMenu } from './TrailingMenu'

import { Icon, Icons, Key, Shortcut, theme, Tooltip } from '@lazy-app/design-system'
import { ThemeProvider, Divider, Box, Typography } from '@mui/material'
import launcher from '@/assets/images/launcher-mobile@4x-compress.png'
import TaskCheckboxView from './TaskCheckBoxView'
import { usePointerCoords } from 'hooks/usePointerCoords'

const THRESHOLD = 5
const COEF_LERP = 0.1
const DELAY_FLASH = 1500

type Props = {
  appear: boolean
}

const Launcher = ({ appear }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const dispatch = useDispatch<AppDispatch>()
  const [width, height] = useWindowSize()
  const [rotaTarget, setRotaTarget] = useState({ rotateX: 0, rotateY: 0 })
  const [canRotate, setCanRotate] = useState<boolean>(false)
  const [, setCanCapture] = useState<boolean>(false)
  const rotaXRef = useRef<number>(0)
  const rotaYRef = useRef<number>(0)
  const { modalRequest, modalTry } = useSelector((state: RootState) => state.launcher)
  const pointerMove = usePointerCoords()

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setCanRotate(false)
      setRotaTarget({ rotateX: 0, rotateY: 0 })
      // @ts-ignore
      if (!modalRequest && !isInEdition(e) && document.activeElement.tagName !== 'INPUT') {
        dispatch(launcherSlice.actions.toggleModalTry(true))
      }
    },
    [dispatch, modalRequest],
  )

  const handleMouseLeave = useCallback(() => {
    dispatch(launcherSlice.actions.toggleModalTry(false))
    if (getEditor()?.view.focused || !modalRequest) return
    setRotaTarget({ rotateX: 0, rotateY: 0 })
  }, [dispatch, modalRequest])

  const handleMouseMove = useCallback(
    (e: TPointCoord) => {
      if (!canRotate || getEditor().isFocused) return
      const { offsetLeft, offsetTop } = ref.current

      const horizontal = (e.x - offsetLeft) / width
      const vertical = (e.y - offsetTop + window.scrollY) / height
      const rotateX = -parseFloat((THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2))
      const rotateY = -parseFloat((vertical * THRESHOLD - THRESHOLD / 2).toFixed(2))

      setRotaTarget({ rotateX, rotateY })

      ref.current.style.transform = `perspective(${width}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`
    },
    [canRotate, width, height],
  )

  const handleRAF = useCallback(() => {
    const { rotateX, rotateY } = rotaTarget
    // if (end) return
    const precision = 3
    if (
      parseFloat(rotateX.toFixed(precision)) === parseFloat(rotaXRef.current.toFixed(precision)) &&
      parseFloat(rotateY.toFixed(precision)) === parseFloat(rotaYRef.current.toFixed(precision))
    ) {
      return
    }

    const { clientWidth } = ref.current
    rotaXRef.current = lerp(rotaXRef.current, rotateX, COEF_LERP)
    rotaYRef.current = lerp(rotaYRef.current, rotateY, COEF_LERP)

    ref.current.style.transform = `perspective(${clientWidth}px) rotateX(${rotaYRef.current}deg) rotateY(${rotaXRef.current}deg) scale3d(1, 1, 1)`
  }, [rotaTarget])

  const handleFocus = useCallback(() => {
    setCanRotate(false)
    dispatch(launcherSlice.actions.toggleModalTry(false))
    setRotaTarget({ rotateX: 0, rotateY: 0 })
    getEditor()?.commands.focus()
  }, [dispatch])

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      if (document.activeElement.tagName === 'INPUT') return
      setCanRotate(true)
      setRotaTarget({ rotateX: 0, rotateY: 0 })
    }, 1000)
  }, [])

  const handleChange = useCallback(() => {
    setRotaTarget({ rotateX: 0, rotateY: 0 })
    setCanCapture(false)
    // dispatch(launcherSlice.actions.setInputValue(textareaRef.current.value))
  }, [])

  const handleClickCapture = useCallback(() => {
    dispatch(launcherSlice.actions.toggleModalRequest(true))
  }, [dispatch])

  useEffect(() => {
    if (!canRotate) {
      ref.current.style.transform = 'perspective(0px) scale3d(1, 1, 1)'
      gsap.ticker.remove(handleRAF)
    } else {
      gsap.ticker.add(handleRAF)
    }

    return () => {
      gsap.ticker.remove(handleRAF)
    }
  }, [handleRAF, dispatch, canRotate])

  useEffect(() => {
    let timeout
    if (appear) {
      timeout = setTimeout(() => {
        dispatch(launcherSlice.actions.setHover(true))
      }, DELAY_FLASH)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [appear, dispatch])

  useEffect(function enableRotateOnFirstRender() {
    const fn = () => {
      if (!document.getElementById('floating-placeholder-LP')?.textContent || getEditor().isFocused) return false

      setCanRotate(true)
      return true
    }
    setTimeout(() => {
      if (fn()) return
    }, 10000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const top = (ref.current.parentNode as HTMLDivElement).offsetTop
      const h = (ref.current.parentNode as HTMLDivElement).offsetHeight

      const finalPos = top + h / 2
      dispatch(launcherSlice.actions.setPosH(finalPos))
    }, 5000)
  }, [dispatch, width, height])

  // inView
  const { ref: inViewRef, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
  })

  // Events
  useEffect(() => {
    const focusArea = (e: KeyboardEvent) => {
      if ((e.code === 'KeyL' && !isInEdition(e)) || (e.code === 'Enter' && e.metaKey)) {
        e.preventDefault()
        e.stopPropagation()
        if (!isInEdition(e)) focusEditor()
        else dispatch(launcherSlice.actions.toggleModalRequest(true))
      } else if (e.code === 'Escape') {
        dispatch(launcherSlice.actions.toggleModalRequest(false))
      } else if (e.code === 'Enter' && e.metaKey) {
        dispatch(launcherSlice.actions.toggleModalRequest(true))
      } else if (e.code === 'Enter' && e.altKey) {
        dispatch(launcherSlice.actions.toggleModalRequest(true))
      }
    }

    if (inView) {
      dispatch(launcherSlice.actions.setTargetable(true))
      window.addEventListener('keydown', focusArea, true)
    } else {
      dispatch(launcherSlice.actions.setTargetable(false))
      window.removeEventListener('keydown', focusArea, true)
    }

    return () => {
      window.removeEventListener('keydown', focusArea, true)
    }
  }, [inView, dispatch, modalTry])

  useEffect(() => {
    if (!canRotate || getEditor().isFocused || !inView) return
    handleMouseMove(pointerMove)
  }, [canRotate, handleMouseMove, inView, pointerMove])

  return (
    <ThemeProvider theme={theme}>
      <div
        ref={inViewRef}
        style={{ position: 'relative', zIndex: 1000 }}
        className={cx(EAppearAnimation.fromBottom, EAppearAnimation.delay2, {
          [EAppearAnimation.show]: appear,
        })}
      >
        <div className={styles.launcher_img}>
          <img src={launcher.src} />
        </div>
        <div ref={ref} className={styles.launcher} onMouseLeave={handleMouseLeave} onMouseOver={handleMouseEnter}>
          <ModalRequest />
          <ModalTry onClick={handleFocus} />
          <div className={styles.bg}>
            <div className={styles.bg_top}></div>
          </div>
          <div className={styles.inner}>
            {appear && (
              <LauncherTextEditor onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} appear={appear} />
            )}
            <div className={styles.link}>
              <LinkSvg className={styles.linkIcon} />
              <p className={ETypography.small}>Lazy · A capture tool for knowledge</p>
            </div>
          </div>
          <div className={styles.bottom}>
            <Divider className={styles.divider} />
            <div className={styles.captureIconWrapper}>
              <CapturesSvg className={styles.captureIcon}></CapturesSvg>
              <p className={styles.captureIconText}>Inbox</p>
            </div>
            <div className={styles.buttonWrapper}>
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-76, 0],
                      },
                    },
                  ],
                }}
                variant="launcher"
                width={250}
                color={theme.palette.grey['100']}
                title={
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      '*': {
                        fontFamily: 'Inter!important',
                        fontSize: '12px',
                      },
                    }}
                  >
                    {[
                      {
                        icon: <Icon icon={Icons.CAPTURE_MINI} size={20} />,
                        title: 'Capture',
                        keysRender: [Key.COMMAND, Key.RETURN],
                      },
                      {
                        icon: (
                          <Box ml={1} mr={0.8}>
                            <TaskCheckboxView size={14} disabled />
                          </Box>
                        ),
                        title: 'Capture as a Task',
                        keysRender: [Key.OPTION, Key.RETURN],
                      },
                    ].map((content) => (
                      <Box display="flex" key={content.title} justifyContent="space-between" alignItems={'center'}>
                        <Box display="flex" alignItems="center">
                          <Box display={'flex'} alignSelf={'center'}>
                            {content.icon}
                          </Box>
                          <Typography noWrap>{content.title}</Typography>
                        </Box>
                        <Box>
                          <Shortcut keys={content.keysRender} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                }
              >
                <div>
                  <Button
                    text="Capture"
                    onClick={handleClickCapture}
                    styleInline={{ display: 'inline-block' }}
                  ></Button>
                </div>
              </Tooltip>
            </div>
          </div>
          <TrailingMenu />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Launcher
