import { getEditor } from '@/utils/TextEditor'
import { isBlockSelection } from '@lazy-app/seshat'
import { Editor, posToDOMRect } from '@tiptap/react'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import tippy, { Instance, Props } from 'tippy.js'

export interface PlaceholderBallonPluginProps {
  pluginKey: PluginKey | string
  editor: Editor
  element: HTMLElement
  tippyOptions?: Partial<Props>
  shouldShow:
    | ((props: {
        editor: Editor
        view: EditorView
        state: EditorState
        oldState?: EditorState
        forceShow?: boolean
      }) => boolean)
    | null
}

export type PlaceholderBallonOptions = Omit<PlaceholderBallonPluginProps, 'editor' | 'element'> & {
  element: HTMLElement | null
}

export type PlaceholderBallonViewProps = PlaceholderBallonPluginProps & {
  view: EditorView
}

export class PlaceholderBallonView {
  public editor: Editor

  public element: HTMLElement

  public view: EditorView

  public preventHide = false

  public tippy!: Instance

  public shouldShow: Exclude<PlaceholderBallonPluginProps['shouldShow'], null> = ({ state }) => {
    const { selection } = state
    const { $anchor, empty } = selection
    const isRootDepth = $anchor.depth === 1
    const isEmptyTextBlock = $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && !$anchor.parent.textContent

    if (!empty || !isRootDepth || !isEmptyTextBlock) {
      return false
    }

    return true
  }

  constructor({ editor, element, view, tippyOptions, shouldShow }: PlaceholderBallonViewProps) {
    this.editor = editor
    this.element = element
    this.view = view

    if (shouldShow) {
      this.shouldShow = shouldShow
    }

    this.element.addEventListener('mousedown', this.mousedownHandler, {
      capture: true,
    })
    this.editor.on('focus', this.focusHandler)
    this.editor.on('blur', this.blurHandler)
    this.createTooltip(tippyOptions)
    this.element.style.visibility = 'visible'

    setTimeout(() => {
      editor.chain().focus().blur().run()
      this.tippy.setProps({
        getReferenceClientRect: () => {
          try {
            const currentView = getEditor().view
            const pos = posToDOMRect(currentView as any, 4, 4)
            return pos
          } catch (err) {
            return new DOMRect()
          }
        },
      })
      this.shouldShow?.({ forceShow: true, editor, view, state: view.state })
    }, 3000)
    this.tippy.show()
  }

  mousedownHandler = () => {
    this.preventHide = true
  }

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view as EditorView))
  }

  blurHandler = ({ event }: { event: FocusEvent }) => {
    if (this.preventHide) {
      this.preventHide = false

      return
    }

    if (event?.relatedTarget && this.element.parentNode?.contains(event.relatedTarget as Node)) {
      return
    }

    if (this.editor.getText().length !== 0) {
      // tippy.hide() blurs the active element which breaks focus restoration
      // when switching apps (e.g with alt+tab) so we have to do it ourselves
      // for more details: https://github.com/lazy-app/lazy/pull/2203#discussion_r908617000
      const activeElement = window.document.activeElement

      this.hide()

      if (activeElement === this.view.dom) {
        // when clicking elsewhere in the app, the editor is blurred
        // and activeElement will be the element that's clicked
        // when switching app, the editor is blurred but it's still the activeElement
        // so that's a good way to restore focus when it shouldn't be lost

        // setTimeout to restore focus after tippy has blurred
        setTimeout(() => (activeElement as HTMLElement).focus())
      }
    }
  }

  createTooltip(options: Partial<Props> = {}) {
    this.tippy = tippy(this.view.dom, {
      duration: 0,
      maxWidth: '100%',
      getReferenceClientRect: null,
      content: this.element,
      trigger: 'manual',
      appendTo: 'parent',
      placement: 'right',
      hideOnClick: 'toggle',
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow', // Scroll with the content https://popper.js.org/docs/v2/modifiers/prevent-overflow/#mainaxis
            options: {
              mainAxis: false,
            },
          },
        ],
      },
      ...options,
    })
  }

  update(view: EditorView, oldState?: EditorState) {
    const { state } = view

    if (!this.editor.isFocused) return

    if (isBlockSelection(state.selection)) {
      this.hide()
      return
    }

    const shouldShow = this.shouldShow({
      editor: this.editor,
      view,
      state,
      oldState,
    })

    if (!shouldShow) {
      this.hide()
      return
    }

    const { doc, selection } = state
    const { from, to } = selection
    const isSame = oldState?.doc.eq(doc) && oldState.selection.$anchor.pos === selection.$anchor.pos

    if (isSame) {
      return
    }

    this.show(view, from, to)
  }

  show(view: EditorView, from: number, to: number) {
    const docSize = view.state.doc.nodeSize
    const isWithinDoc = from < docSize && to < docSize
    if (!isWithinDoc) return
    setTimeout(() => {
      this.tippy.setProps({
        getReferenceClientRect: () => {
          try {
            return posToDOMRect(view as any, from, to)
          } catch (err) {
            return new DOMRect()
          }
        },
      })
    })
    this.tippy.show()
  }

  hide() {
    this.tippy.hide()
  }

  destroy() {
    this.tippy.destroy()
    this.element.removeEventListener('mousedown', this.mousedownHandler)
    this.editor.off('focus', this.focusHandler)
    this.editor.off('blur', this.blurHandler)
  }
}

export const PlaceholderBallonPlugin = (options: PlaceholderBallonPluginProps) => {
  return new Plugin({
    key: typeof options.pluginKey === 'string' ? new PluginKey(options.pluginKey) : options.pluginKey,
    view: (view) => new PlaceholderBallonView({ view, ...options }),
  })
}
